import { stripe, masterCard } from "../../assetsImports.js"
export const billingHistory = [
    {
        invoiceDate: "2024/01/12",
        paymentDate: "2024/02/12",
        receipt: ""
    },
    {
        invoiceDate: "2024/02/12",
        paymentDate: "2024/03/12",
        receipt: ""
    },
    {
        invoiceDate: "2024/03/12",
        paymentDate: "2024/04/12",
        receipt: ""
    },
    {
        invoiceDate: "2024/04/12",
        paymentDate: "2024/05/12",
        receipt: ""
    },
    {
        invoiceDate: "2024/05/12",
        paymentDate: "2024/06/12",
        receipt: ""
    },
    {
        invoiceDate: "2024/06/12",
        paymentDate: "2024/07/12",
        receipt: ""
    },
    {
        invoiceDate: "2024/07/12",
        paymentDate: "2024/08/12",
        receipt: ""
    },
    {
        invoiceDate: "2024/08/12",
        paymentDate: "2024/09/12",
        receipt: ""
    },
    {
        invoiceDate: "2024/09/12",
        paymentDate: "2024/10/12",
        receipt: ""
    },
    {
        invoiceDate: "2024/10/12",
        paymentDate: "2024/11/12",
        receipt: ""
    }
]
export const payementMethods = [
    // {
    //     type: "Visa",
    //     cardNumber: 414411231231,
    //     img: visa
    // },
    // {
    //     type: "PayPal",
    //     email: "dummy@gmail.com",
    //     img: paypal
    // },
    {
        type: "mastercard",
        cardNumber: 414411231231,
        img: masterCard
    },
    {
        type: "Stripe",
        email: "dummy@gmail.com",
        img: stripe
    }
]
export const plan = {
    expiryDate: "Standard",
    paymentDate: "2024/02/3",
}