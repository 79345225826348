import { useState } from "react";
import "./AddTeacher.css";
import { profileAdd, addTeacherFormBg, plusIcon, penEditIcon } from "../../../assetsImports.js";

function AddTeacher({ handleAddTeacherBtn }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [fullName, setFullName] = useState({
        firstName: "",
        lastName: ""
    });
    const [contacts, setContacts] = useState({
        email: "",
        phoneNumber: "",
        address: ""
    });
    const [dob, setDob] = useState("");
    const [gender, setGender] = useState([
        { name: "Male", status: false },
        { name: "Female", status: false },
    ]);
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleSetFullName = (e, section) => setFullName((prevFullName) => {
        const newFullName = { ...prevFullName };
        if (section === "firstName") newFullName.firstName = e.target.value;
        else newFullName.lastName = e.target.value;
        return newFullName;
    });
    const handleSetContacts = (e, section) => setContacts((prevContacts) => {
        const newContacts = { ...prevContacts };
        if (section === "email") newContacts.email = e.target.value;
        else if (section === "phoneNumber") {
            newContacts.phoneNumber = isNaN(e.target.value) ? newContacts.phoneNumber : e.target.value;
        } else newContacts.address = e.target.value;
        return newContacts;
    });
    const handleDob = (e) => setDob(() => { return e.target.value });
    const handleSetGender = (value) => setGender((prevGender) => {
        const newGender = [...prevGender];
        newGender.forEach((elem) => {
            if (elem.name === value) elem.status = true;
            else elem.status = false;
        });
        return newGender;
    });
    const selectedGender = () => {
        const selGend = gender.find(({ status }) => status);
        return selGend ? selGend.name : `Select Gender`
    };
    const genderVisibility = () => {
        const genderList = document.getElementById("genderList");
        if (!genderList.checkVisibility()) genderList.style.display = "flex";
        else genderList.style.display = "none";
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('profileImage', selectedFile);
        formData.append('firstName', fullName.firstName);
        formData.append('lastName', fullName.lastName);
        formData.append('email', contacts.email);
        formData.append('phoneNumber', contacts.phoneNumber);
        formData.append('address', contacts.address);
        formData.append('gender', gender);
        formData.append('dob', dob);

        const request = await fetch('https://apiendpoint.com/upload', {
            method: 'POST',
            body: formData,
        });
        const response = request.json();
        console.log(response);
    };
    return <form className="AddTeacher" style={{ backgroundImage: `url(${addTeacherFormBg})` }}>

        <div className="profilePicture" data-testid="AddTeacher">
            <div className="circle"
                onClick={() => {
                    document.getElementById("addImInputField").click();
                }}>
                <img src={previewUrl ? previewUrl : profileAdd} alt="add profile"></img>
                <div className="editAdd">
                    <img src={previewUrl ? penEditIcon : plusIcon} alt="edit icon"></img>
                </div>
            </div>
            <input
                id="addImInputField"
                className="imageInputField"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
            ></input>
        </div>
        <div className="fullName">
            <input placeholder="First Name"
                value={fullName.firstName}
                onChange={(e) => handleSetFullName(e, "firstName")}
                data-testid="AddTeacher-FirstName"
            ></input>
            <input placeholder="Last Name"
                value={fullName.lastName}
                onChange={(e) => handleSetFullName(e, "lastName")}
                data-testid="AddTeacher-LastName"
            ></input>
        </div>
        <div className="contacts">
            <input placeholder="E-mail"
                value={contacts.email}
                onChange={(e) => handleSetContacts(e, "email")}
                data-testid="AddTeacher-Email"
            ></input>
            <input placeholder="Phone Number"
                value={contacts.phoneNumber}
                onChange={(e) => handleSetContacts(e, "phoneNumber")}
                data-testid="AddTeacher-PhoneNumber"
            ></input>
            <input placeholder="Address"
                className="address"
                value={contacts.address}
                onChange={(e) => handleSetContacts(e, "address")}
                data-testid="AddTeacher-Address"
            ></input>
        </div>
        <div className="genderDob">
            <div className="gender" onClick={() => genderVisibility()}>
                <div className="selectedGender">{selectedGender()}</div>
                <div className="genderList" id="genderList">
                    {
                        gender.map((elem, i) => <div
                            key={elem.name}
                            className="genderElm"
                            style={i === gender.length - 1 ? { borderBottomRightRadius: 10, borderBottomLeftRadius: 10 } : {}}
                            onClick={() => handleSetGender(elem.name)}
                        >{elem.name}</div>)
                    }
                </div>
            </div>
            <label className="dob">
                <span>
                    Date Of Birth:
                </span>
                <input
                    data-testid="AddTeacher-Dob"
                    type="date"
                    value={dob}
                    onChange={handleDob} />
            </label>
        </div>
        <div className="submission">
            <button
                className="addTeacherBtn"
                onClick={(e) => handleSubmit(e)}
                data-testid="AddTeacher-submission" >Add</button>
            <button className="discTeacherBtn"
                onClick={(e) => { e.preventDefault(); handleAddTeacherBtn() }}
                data-testid="AddTeacher-discTeacherBtn">Discard</button>
        </div>
    </form>;
}

export default AddTeacher;