import moment from "moment";

export function todayDate() {
    const today = new Date();
    const month = today.getMonth();
    const day = today.getDate();
    const date = today.getDay();
    const year = today.getFullYear();
    return ({ month, day, year, date });
}
export function yearsListInit(currentYear) {
    const listOfYears = [];
    listOfYears.push(currentYear);
    //adding 7 years in the future
    for (let i = 1; i < 5; i++)
        listOfYears.push(currentYear + i);
    for (let i = 1; i < 5; i++)
        listOfYears.unshift(currentYear - i);
    return listOfYears;
}

export function getMonthName(monthNumber) {
    const operatingSystem = navigator.platform;
    const isMac = operatingSystem.includes("Mac");


    let date;
    if (operatingSystem) date = new Date(Date.UTC(2000, isMac ? monthNumber : monthNumber - 1, 1));
    else {
        //Testing env handler
        const reactEnv = process.platform;
        const isReactEnvMac = reactEnv.includes("darwin");
        date = new Date(Date.UTC(2000, isReactEnvMac ? monthNumber : monthNumber - 1, 1));
    }



    return date.toLocaleString('en-US', { month: 'long' });
}
export function convertDayOfWeekToNumber(dayOfWeek) {
    // Create an array representing the days of the week
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Find the index of the given dayOfWeek in the array
    const index = daysOfWeek.indexOf(dayOfWeek);

    // If the dayOfWeek is found, return its index, otherwise return -1
    return index !== -1 ? index : -1;
}
export function getDaysInMonth(year, month) {

    const daysInMonth = moment(`${year}-${month}`, "YYYYMMDD").daysInMonth();

    const daysList = [];
    for (let date = 1; date <= daysInMonth; date++) {
        const currentDate = new Date(year, month - 1, date);
        const dayName = currentDate.toLocaleString('en-US', { weekday: 'long' });
        daysList.push({ day: date, nameOfTheDay: dayName, month: month });
    }
    return daysList;
}
export function filterDayIntoDayColumn(daysInMonth) {
    const dayColumns = [{
        name: "Sunday",
        days: []
    }, {
        name: "Monday",
        days: []
    }, {
        name: "Tuesday",
        days: []
    }, {
        name: "Wednesday",
        days: []
    }, {
        name: "Thursday",
        days: []
    }, {
        name: "Friday",
        days: []
    }, {
        name: "Saturday",
        days: []
    }];
    daysInMonth.forEach(day => {
        switch (day['nameOfTheDay']) {
            case "Sunday":
                dayColumns[0].days.push(day);
                break;
            case "Monday":
                dayColumns[1].days.push(day);
                break;
            case "Tuesday":
                dayColumns[2].days.push(day);
                break;
            case "Wednesday":
                dayColumns[3].days.push(day);
                break;
            case "Thursday":
                dayColumns[4].days.push(day);
                break;
            case "Friday":
                dayColumns[5].days.push(day);
                break;
            case "Saturday":
                dayColumns[6].days.push(day);
                break;
            default:
                break;
        }
    });
    return dayColumns;
}
function dayToIndex(day) {
    let conversion;
    switch (day) {
        case "Sunday":
            conversion = 0;
            break;
        case "Monday":
            conversion = 1;
            break;
        case "Tuesday":
            conversion = 2;
            break;
        case "Wednesday":
            conversion = 3;
            break;
        case "Thursday":
            conversion = 4;
            break;
        case "Friday":
            conversion = 5;
            break;
        case "Saturday":
            conversion = 6;
            break;
        default:
            break;
    }
    return conversion;
}
export function addingDaysFromPrevOrNextMonth(dayColumns, year, month) {
    const newDayColumns = JSON.parse(JSON.stringify(dayColumns));

    const prevData = { year: year, month: month };
    if (month - 1 < 1) { prevData.year = prevData.year - 1; prevData.month = 12 }
    else prevData.month = prevData.month - 1;

    const nextData = { year: year, month: month };
    if (month + 1 > 12) { nextData.year = nextData.year + 1; nextData.month = 1 }
    else nextData.month = nextData.month + 1;

    const prevMonth = getDaysInMonth(prevData.year, prevData.month);
    const nextMonth = getDaysInMonth(nextData.year, nextData.month);

    let firstDayOfMonth;
    let lastDayOfMonth;
    const daysInMonth = moment(`${year}-${month}`, "YYYYMMDD").daysInMonth();

    dayColumns.forEach((column) => {
        if (column.days[0].day === 1)
            firstDayOfMonth = dayToIndex(column.days[0].nameOfTheDay);
        if (column.days[column.days.length - 1].day === daysInMonth)
            lastDayOfMonth = dayToIndex(column.days[0].nameOfTheDay);
    });
    //upper Missing Days
    for (let i = 0; i < firstDayOfMonth; i++) {
        const difference = firstDayOfMonth - i;
        newDayColumns[i].days.unshift(prevMonth[prevMonth.length - difference]);
    }

    //lower Missing Days
    let k = 0;
    for (let i = lastDayOfMonth + 1; i <= 6; i++) {
        newDayColumns[i].days.push(nextMonth[k]);
        k++;
    }

    //adding additional row for UI purpose when necessary (to have 6 rows on the calendar)
    if (newDayColumns[0].days.length < 6) {
        for (let i = 0; i <= 6; i++) {
            newDayColumns[i].days.push(nextMonth[k]);
            k++;
        }
    }
    //not leap year
    if (newDayColumns[0].days.length < 6) {
        for (let i = 0; i <= 6; i++) {
            newDayColumns[i].days.push(nextMonth[k]);
            k++;
        }
    }
    return newDayColumns;
}