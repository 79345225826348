import "./TeacherNavBar.css";
import { searchIcon } from "../../../assetsImports.js";
import { AddTeacher, SystemPreferences, darkModePallete } from "../../../Admin/AdminImports.js";
import { useState, useContext } from "react";
import { CSVLink } from "react-csv";

const exportTeacherData = (teachersData) => {
    const columns = [
        "firstName", "lastName",
        "email", "phoneNumber",
        "dob", "gender",
        "address", "about"];
    let csvFormat = columns.join(",");
    csvFormat += '\n';
    teachersData.forEach((teacher) => {
        csvFormat += `${teacher.firstName},${teacher.lastName},${teacher.email},${teacher.phoneNumber},${teacher.dob},${teacher.gender},${teacher.address.replace(/,/g, ";")},${teacher.about}\n`;
    });
    return csvFormat;
}
const todaysDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const todayDate = `${year}-${month}-${day}`;

    return todayDate;
}
function TeacherNavBar({ teachers }) {
    const { adminView } = useContext(SystemPreferences);
    const [addTeacherBtn, setAddTeacherBtn] = useState(false);
    const handleAddTeacherBtn = () => setAddTeacherBtn((prevAddTeacherBtn) => { return !prevAddTeacherBtn });
    return <nav className="TeacherNavBar" data-testid={`TeacherNavBar`}>
        <div className="searchEngTeacher" style={adminView.viewMode ? { ...darkModePallete.shadow, ...darkModePallete.secondaryBackground } : {}}>
            <img src={searchIcon} alt="search icon" ></img>
            <input placeholder="Teacher's name" style={adminView.viewMode ? darkModePallete.secondaryBackground : {}}></input>
        </div>
        <div className="options">
            <button
                style={adminView.viewMode ? darkModePallete.shadow : {}}
                data-testid="TeacherNavBar-AddTeacherBtn"
                className="addTeacher"
                onClick={() => handleAddTeacherBtn()}>
                Add Teachers
            </button>
            <CSVLink data={exportTeacherData(teachers)} filename={`${'schoolName'}${todaysDate()}`}>
                <button  data-testid="TeacherNavBar-exportCsv" className="exportCsv">Export Csv</button>
            </CSVLink>
        </div>
        {addTeacherBtn && <AddTeacher handleAddTeacherBtn={handleAddTeacherBtn} />}
    </nav>;
}
export default TeacherNavBar;