import { useState, useContext } from 'react'
import { kebabIcon, groupIcon, trashIcon } from '../../../../assetsImports';
import { SystemPreferences, darkModePallete, RoleChange, DeleteConfirmation } from "../../../../Admin/AdminImports.js";
import './UsersList.css';

const dummyData = [
    { id: 1, photo: 'https://via.placeholder.com/40', firstName: 'John', lastName: 'Doe', role: ['Admin'], status: 'Active' },
    { id: 2, photo: 'https://via.placeholder.com/40', firstName: 'Jane', lastName: 'Smith', role: ['Student'], status: 'Inactive' },
    { id: 3, photo: 'https://via.placeholder.com/40', firstName: 'Sam', lastName: 'Wilson', role: ['SuperAdmin'], status: 'Active' },
    { id: 4, photo: 'https://via.placeholder.com/40', firstName: 'Sam', lastName: 'Wilson', role: ['Teacher'], status: 'Active' }
];


const UserActions = ({ onChangeRoles, onDelete }) => {
    return (
        <div className={`dropdown-menu`}>
            <div onClick={onChangeRoles} className={`menu-item change-roles-menu`}>
                <img src={groupIcon} alt='users icon' />
                <p>Change roles</p>
            </div>
            <div onClick={onDelete} className={`menu-item delete-user-menu`}>
                <img src={trashIcon} alt='users icon' />
                <p>Delete user</p>
            </div>
        </div>
    )
}

const UsersList = () => {
    const [openDropdownAction, setOpenDropdownAction] = useState(null);
    const [users, setUsers] = useState(dummyData);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showRoleChange, setShowRoleChange] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [searchUser, setSearchUser] = useState('');
    const { adminView } = useContext(SystemPreferences);
    const [checkedItems, setCheckedItems] = useState(dummyData.map(() => false));
    const [allChecked, setAllChecked] = useState(false);


    const handleUserAction = (userId) => {
        setOpenDropdownAction(openDropdownAction === userId ? null : userId);
    };

    const handleDeleteClick = (userId) => {
        setDeleteUserId(userId);
        setShowDeleteConfirmation(true);
    }

    const handleDeleteConfirm = () => {
        setUsers(users.filter((user) => user.id !== deleteUserId));
        setShowDeleteConfirmation(false);
        setOpenDropdownAction(null);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmation(false);
        setOpenDropdownAction(null);
    };

    const handleChangeRolesClick = (userId) => {
        setSelectedUserId(userId);
        setShowRoleChange(true);
    }

    const handleSaveRoles = (newRoles) => {
        setUsers(
            users.map((user) =>
                user.id === selectedUserId ? { ...user, role: newRoles } : user
            )
        );
        setShowRoleChange(false);
        setOpenDropdownAction(null);
    }


    const handleCancelChangeRoles = () => {
        setShowRoleChange(false);
        setOpenDropdownAction(null);
    }

    const handleCheckAll = () => {
        const newCheckedState = !allChecked;
        setCheckedItems(dummyData.map(() => newCheckedState));
        setAllChecked(newCheckedState);
    };

    const handleCheckItem = (index) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = !newCheckedItems[index];
        setCheckedItems(newCheckedItems);
        setAllChecked(newCheckedItems.every((item) => item));
    };

    const filteredUser = dummyData.filter((user) => {

        const searchTerm = searchUser.toLowerCase();
        const searchMatch = !searchTerm || user.firstName.toLowerCase().includes(searchTerm) || user.lastName.toLowerCase().includes(searchTerm);

        return searchMatch;
    })

    const totalUsers = dummyData.length;

    return (
        <div className='users-list'>
            <div className='users-list-header'>
                <div className='users-list-header'>
                    <h4>
                        All Users  <span> {totalUsers}</span>
                    </h4>
                </div>
                <div className='users-search-bar-wrapper'>
                    <input
                        placeholder='Search users'
                        type='text'
                        onChange={(e) => setSearchUser(e.target.value)}
                        id='users-search-input'
                        data-testid='users-search-input'
                        className={`users-search-bar`}
                    />
                </div>
            </div>
            <table>
                <thead>
                    <tr style={adminView.viewMode ? {...darkModePallete.textColor,...darkModePallete.primaryBackground } : {}}>
                        <th>
                            <input
                                type='checkbox'
                                id='all-user-checkbox'
                                data-testid='check-all'
                                checked={allChecked}
                                onChange={handleCheckAll}
                            />
                        </th>
                        <th>
                            <p>Name</p>
                        </th>
                        <th>
                            <p>Roles</p>
                        </th>
                        <th>
                            <p>Status</p>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUser.map((user, index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type='checkbox'
                                    id={`userList-checkBox-${user.id}`}
                                    style={adminView.viewMode ? darkModePallete.textColor : {}}
                                    checked={checkedItems[index]}
                                    onChange={() => handleCheckItem(index)}
                                />
                            </td>
                            <td>
                                <div className='user-profile'>
                                    <img src={user.photo} alt='user avatar' />
                                    <p>{user.firstName + " " + user.lastName}</p>
                                </div>
                            </td>
                            <td>{user.role.join(', ')}</td>
                            <td>{user.status}</td>
                            <td>
                                <img onClick={() => handleUserAction(user.id)} className={`${openDropdownAction === user.id ? 'clicked-user-options' : 'user-options'} `} src={kebabIcon} alt='options' />
                                {openDropdownAction === user.id && (<UserActions onChangeRoles={() => handleChangeRolesClick(user.id)} onDelete={() => handleDeleteClick(user.id)} />)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showRoleChange && (
                <RoleChange
                    userRoles={users.find((user) => user.id === selectedUserId).role}
                    onSave={handleSaveRoles}
                    onCancel={handleCancelChangeRoles}
                />

            )}

            {showDeleteConfirmation && (
                <DeleteConfirmation
                    onDelete={handleDeleteConfirm}
                    onCancel={handleCancelDelete}
                />
            )}
        </div>
    );
};

export default UsersList;