import { useContext } from "react";
import "./InfoCard.css";
import { teacherContext, SystemPreferences, darkModePallete } from "../../../Admin/AdminImports.js";
import { phoneIcon, mailIcon, copyIcon } from "../../../assetsImports.js";

function InfoCard() {
    const { selTeach } = useContext(teacherContext);
    const { adminView } = useContext(SystemPreferences);

    const teacherAge = (new Date()).getFullYear() - (new Date(selTeach.dob)).getFullYear();
    const copyInfoToClipBoard = async () => {
        let formattedData = '';
        const fieldToCopy = [
            "firstName",
            "lastName",
            "email",
            "phoneNumber",
            "dob",
            "gender",
            "address",
            "about",
        ];

        fieldToCopy.forEach((key) => {
            if (selTeach.hasOwnProperty(key)) {
                formattedData += `${key}: ${selTeach[key]}\n`;
            }
        });
        try {
            await navigator.clipboard.writeText(formattedData);

            const alertBox = document.createElement('div');
            alertBox.textContent = 'Data copied to clipboard!';
            alertBox.style.position = 'absolute';
            alertBox.style.bottom = '30px';
            alertBox.style.color = 'green';
            alertBox.style.right = '60px';
            alertBox.style.padding = '10px';
            alertBox.style.backgroundColor = '#f0f0f0';
            alertBox.style.border = '1px solid #ccc';
            alertBox.style.borderRadius = '5px';
            alertBox.style.zIndex = '9999';

            document.body.appendChild(alertBox);
            setTimeout(() => {
                document.body.removeChild(alertBox);
            }, 2000);
        } catch (err) {
            const alertBox = document.createElement('div');
            alertBox.textContent = 'Failed to copy to clipboard!';
            alertBox.style.position = 'absolute';
            alertBox.style.bottom = '30px';
            alertBox.style.color = 'red';
            alertBox.style.right = '60px';
            alertBox.style.padding = '10px';
            alertBox.style.backgroundColor = '#f0f0f0';
            alertBox.style.border = '1px solid #ccc';
            alertBox.style.borderRadius = '5px';
            alertBox.style.zIndex = '9999';

            document.body.appendChild(alertBox);
            setTimeout(() => {
                document.body.removeChild(alertBox);
            }, 2000);
        }
    }
    return <div className="InfoCard"
        data-testid={`InfoCard`}
        style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.shadow, ...darkModePallete.textColor } : {}}>
        <div className="UpperPart">
            <div className="legend">
                <h3>Teacher Profile</h3>
                <img
                    alt="copy info icon"
                    data-testid="InfoCard-copyBtn"
                    src={copyIcon}
                    style={adminView.viewMode ? darkModePallete.icon : {}}
                    onClick={() => copyInfoToClipBoard()}
                ></img>
            </div>
            <div className="profile">
                <div className="profileCirle">
                    <img alt="teacher's profile" src={`${selTeach.photo}`}></img>
                </div>
                <div className="InfoCard-Name-disciplines">
                    <h3 className="Name" data-testid="InfoCard-Name" style={adminView.viewMode ? { color: "#3fa9f5" } : {}}>{`${selTeach.firstName} ${selTeach.lastName}`}</h3>
                    <div className="disciplines">
                        {
                            selTeach.discipline.map((subject) =>
                                <span key={subject} className="course" data-testid="InfoCard-Courses" style={adminView.viewMode ? { color: "#9b9ea4" } : {}}>{subject}</span>
                            )
                        }
                    </div>
                </div>

                <div className="contacts">
                    <div className="contactIcons">
                        <a href={`tel:${selTeach.phoneNumber}`}><img className="phoneIcon" alt="contact icon" src={phoneIcon}></img></a>
                    </div>
                    <div className="contactIcons">
                        <a href={`mailto:${selTeach.email}`}><img className="emailIcon" alt="contact icon" src={mailIcon}></img></a>
                    </div>
                </div>
            </div>
        </div>
        <div className="about">
            <h3>About</h3>
            <p data-testid="InfoCard-About" className="aboutText">{selTeach.about}</p>
        </div>
        <div className="additionalInfo">
            <div className="age elm">
                <span className="title">Age</span>
                <span className="value" data-testid="InfoCard-Age">{teacherAge}</span>
            </div>
            <div className="gender elm">
                <span className="title">Gender</span>
                <span className="value" data-testid="InfoCard-Gender">{selTeach.gender}</span>
            </div>
            <div className="dob elm">
                <span className="title">Date Of Birth</span>
                <span className="value" data-testid="InfoCard-dob">{selTeach.dob}</span>
            </div>
            <div className="address elm">
                <span className="title">Address</span>
                <span className="value" data-testid="InfoCard-address">{selTeach.address}</span>
            </div>

        </div>
    </div>;
}
export default InfoCard;