import { useState, useRef,useContext } from "react";
import { SystemPreferences, darkModePallete, countries } from "../../../Admin/AdminImports.js"
import { plusIcon } from "../../../assetsImports";
import "./General.css"



const SchoolDetail = () => {
    const [uploadImg, setUploadImg] = useState(plusIcon);
    const uploadImgRef = useRef();

    const handleUploadImg = (e) => {
        e.preventDefault();

        if (uploadImgRef.current){
            uploadImgRef.current.click();
        }
    }

    const uploadImgDisplay = () => {
        const uploadFile = uploadImgRef.current.files[0];
        const cachedImg = URL.createObjectURL(uploadFile);
        setUploadImg(cachedImg);
    }

    const handleResetImg = (e) => {
        e.preventDefault();
        setUploadImg(plusIcon);
    }

    return (
        <div className={`logo-update`}>
            <img src={uploadImg} alt='business logo' />
            <button  onClick={handleUploadImg} className={`upload-btn`}>Upload new</button>
            <input type="file" ref={uploadImgRef} onChange={uploadImgDisplay} hidden></input>
            <button onClick={handleResetImg} className={`delete-btn`}>Delete</button>
        </div>
    )
}

const RenderInput = (id, label, type) => {
    const { adminView } = useContext(SystemPreferences);
    const [activeInput, setActiveInput] = useState(false);

    const handleFocus = () => {
        setActiveInput(true);
    }

    const handleBlur = (e) => {
        if (e.target.value === ''){
            setActiveInput(false);
        }
    }

    return (
        < div className="input-wrap" >
            <input
                type={type}
                id={id}
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={adminView.viewMode ? { paddingLeft: "10px", ...darkModePallete.primaryBackground, ...darkModePallete.textColor } : {}}
                className={`input-field`}
            />
            <label style={adminView.viewMode ? { paddingLeft: "10px", ...darkModePallete.primaryBackground, ...darkModePallete.textColor } : {}} className={` input-label ${activeInput ? 'label-animate':''}`} htmlFor={id}>{label}</label>
        </div >
    );
}

const renderSelect = (id, label, options) => (
    <div className="input-wrap">
        <select id={id} className="input-field" defaultValue="">
            <option value="" disabled>{label}</option>
            {options.map(option => (
                <option key={option} value={option}>{option}</option>
            ))}
        </select>
        <label htmlFor={id}>{label}</label>
    </div>
);

const ContactForm = () => {
    return (
        <div className={`contact-form-wrapper`}>
            {RenderInput("name", "Name", "text")}
            {RenderInput("email", "Email", "email")}
            {RenderInput("num", "Phone", "number")}
        </div>
    )
}

const AddressForm = () => {
    return (
        <div className={`address-form-wrapper`}>
            {RenderInput("street", "Street", "text")}
            {renderSelect("country", "Country", countries)}
            {RenderInput("city", "City", "text")}
            {RenderInput("postcode", "Postcode", "number")}
        </div>
    )
}

function General() {
    const { adminView } = useContext(SystemPreferences)
    return <div className={`general-container`}>
        <div className={`title`}>
            <h2 style={adminView.viewMode ? darkModePallete.textColor : {}}>General</h2>
            <span>Udpate your business profile</span>
        </div>

        <form autoComplete="off">
            <h4 style={adminView.viewMode ? darkModePallete.textColor : {}}>School Details</h4>
            <div className={`school-details`}>
                <SchoolDetail />
                <ContactForm />
            </div>

            <div className={`address-title`}>
                <h4 style={adminView.viewMode ? darkModePallete.textColor : {}}>Address</h4>
            </div>
            <AddressForm />

            <div className="form-actions">
                <button type="submit" className="submit-btn">Submit</button>
                <button type="button" className="cancel-btn">Cancel</button>
            </div>
        </form>

    </div>
}
export default General;