import { useEffect, useState, useContext } from 'react';
import { closeIcon } from '../../../assetsImports';
import { SystemPreferences, darkModePallete } from "../../../Admin/AdminImports.js";
import { CSVLink } from 'react-csv';
import './PopUpExport.css';

const todaysDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const todayDate = `${year}-${month}-${day}`;

    return todayDate;
}

function PopUpExport({
    handlePopUpExport,
    toExportVal
}) {
    const { adminView } = useContext(SystemPreferences)

    const [csv, setCsv] = useState('');

    const handleCsv = (studentList) => {
        let csvFormat = 'stu_id,lastName,firstName,dateOfBirth,religion,gender,class,classCompletionStatus,address\n';
        studentList.forEach((student) => {
            const buffer = `${student.stu_id},${student.lastName},${student.firstName},${student.dateOfBirth},${student.religion},${student.gender},${student.stu_class},${student.classCompletionStatus},${student.address}\n`;
            csvFormat += buffer;
        });
        setCsv(csvFormat);
    };
    useEffect(() => {
        handleCsv(toExportVal);
    }, [toExportVal]);

    useEffect(() => {
        const popUp = document.getElementById("PopUpExport");
        const handleClick = (event) => {
            if (popUp && !popUp.contains(event.target))
                handlePopUpExport()
        }
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [handlePopUpExport]);
    return (
        <div className="PopUpExport"
            id="PopUpExport"
            style={adminView.viewMode ? { ...darkModePallete.tertiaryBackground, boxShadow: "0px 0px 2px gray" } : {}}
            data-testid={`popUpExport-adminStudentAddExport`}
        >
            <div className='upperSec'>
                <h4 style={adminView.viewMode ? darkModePallete.textColor : {}}
                >Method of export</h4>
                <div className='closeBtn' >
                    <img style={adminView.viewMode ? darkModePallete.icon : {}}
                        src={closeIcon} data-testid={`popUpExport-close-icon-adminStudentAddExport`} alt='close icon' onClick={() => handlePopUpExport()}></img>
                </div>
            </div>
            <div className='studentSelected'>
                <table>
                    <thead style={adminView.viewMode ? { ...darkModePallete.tertiaryBackground, ...darkModePallete.textColor } : {}}
                    >
                        <tr>
                            <th>Photo</th>
                            <th>Name</th>
                            <th>Class</th>
                            <th>Student Id</th>
                        </tr>

                    </thead>
                    <tbody>
                        {
                            toExportVal.map((student, index) =>
                                <tr key={index}
                                    style={adminView.viewMode ? { ...darkModePallete.textColor, ...darkModePallete.secondaryBackground } : {}}

                                    className='studentDetails'>
                                    <td><img src={student['photo']} alt='student '></img></td>
                                    <td>{student['firstName']}</td>
                                    <td>{student['class']}</td>
                                    <td>{student['stu_id']}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>

            </div>

            <div className='methods'>
                <CSVLink data={csv} filename={`${'shoolName'}${todaysDate()}`}><button className='Csv' >Csv</button></CSVLink>
                <button className='Xls'>Xls</button>
                <button className='Pdf'>Pdf</button>
            </div>

        </div>
    );
}

export default PopUpExport;