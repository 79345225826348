import { useContext } from "react";
import { lightMode, darkMode, tickIcon } from "../../../../assetsImports.js";
import { SystemPreferences } from "../../../../Admin/AdminImports.js";
import "./InterfaceTheme.css";

function InterfaceTheme() {
    const { adminView, handleAdminView } = useContext(SystemPreferences)

    return (<div className="InterfaceTheme">
        <div className="title">
            <h3>Interface Theme</h3>
            <span>Select your prefered Theme</span>
        </div>
        <div className="modes">
            <div className="ligthMode"
                onClick={() => handleAdminView("viewMode", false)}
                style={{ "backgroundImage": `url(${lightMode})`, borderColor: adminView.viewMode ?  "#a8a8a8": "#0099ff" }}>
                {!adminView.viewMode && <img className="tick" src={tickIcon} alt="tick icon"></img>}
            </div>
            <div className="darkMode"
                onClick={() => handleAdminView("viewMode", true)}
                style={{ "backgroundImage": `url(${darkMode})`, borderColor: adminView.viewMode ?  "#0099ff": "#a8a8a8" }}>
                {adminView.viewMode && < img className="tick" src={tickIcon} alt="tick icon"></img>}
            </div>
        </div>

    </div >)
}
export default InterfaceTheme;