import { useContext } from "react";
import "./SideBarSettings.css";
import { MenuContext, SystemPreferences, darkModePallete } from "../../../Admin/AdminImports.js"
function SideBarSettings() {
    const { menu, handleMenu } = useContext(MenuContext);
    const { adminView } = useContext(SystemPreferences);
    return <nav className="SideBarSettings">
        {
            menu.map((option) => {

                return <div
                    style={adminView.viewMode ? darkModePallete.textColor : {color:"#6e6e6e"}}
                    className={option.status ? "optionClicked" : "option"}
                    key={option.name}
                    onClick={() => handleMenu(option.name)}>
                    <img style={adminView.viewMode ? darkModePallete.icon : {}}id={`${option.name}svg`} src={option.icon} alt={`${option.name} icon`}></img>
                    <span>
                        {option.name}
                    </span>
                </div>
            }
            )
        }
    </nav>
}

export default SideBarSettings;