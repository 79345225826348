import { useState, useContext } from 'react';
import {
    PopUpEdit, PopUpDelete,
    SystemPreferences, darkModePallete,
    StudentContext
} from '../../../../Admin/AdminImports'
import { penEditIcon, trashIcon } from '.././../../../assetsImports'
import { fieldNameFormat } from '../../../useFullFunctions/useFullFunc';

const DetailBlock = ({ blockName, blockData, blockDataAdditional }) => {
    const { adminView } = useContext(SystemPreferences);
    return (
        <div className="DetailBlock">
            <span className="blockName" style={adminView.viewMode ? darkModePallete.textColor : {}}>{fieldNameFormat(blockName)}</span>
            <span className="blockData" data-testid={`BasicDetails-detailBlock-${blockName}`} style={adminView.viewMode ? { color: "#00A2E3" } : {}}>{blockData}</span>
            {
                blockDataAdditional.length > 0 ?
                    blockDataAdditional.map((data, i) =>
                        <span
                            className="blockData"
                            key={i.toString()}
                            style={adminView.viewMode ? { color: "#00A2E3" } : {}}
                            data-testid={`BasicDetails-detailBlock-${blockName} ${data['value']}`}
                        >{data['value']}
                        </span>
                    ) :
                    <></>
            }
        </div>)
};

const BasicDetails = () => {
    const { studentSelected} = useContext(StudentContext)
    const { adminView } = useContext(SystemPreferences);


    const [popUpEdit, setPopUpEdit] = useState(false);
    const handlePopUpEdit = () => setPopUpEdit((prevVal) => !prevVal);
    const [popUpDelete, setPopUpDelete] = useState(false);
    const handlePopUpDelete = () => setPopUpDelete((prevVal) => !prevVal);


    const studentkeysToAvoid = (elem) =>
        elem !== 'stu_id' && elem !== 'courses'
        && elem !== 'presence' && elem !== 'classCompletionStatus'
        && elem !== 'photo' && elem !== 'class';

    const studentDataKeys = Object.keys(studentSelected).filter((elem) => studentkeysToAvoid(elem));

    return (
        <div className="BasicDetails">
            {popUpEdit && <PopUpEdit data={studentSelected} handlePopUpEdit={handlePopUpEdit} />}
            {popUpDelete && <PopUpDelete data={studentSelected} handlePopUpDelete={handlePopUpDelete} />}
            <div className='Title'>
                <h3 style={adminView.viewMode ? { color: "#00A2E3" } : {}}>Basic Details</h3>
                <div className='edit-delete-container'>
                    <img onClick={() => handlePopUpEdit()} src={penEditIcon} alt='edit data icon' data-testid={'BasicDetails-PopUpEditbtn'}></img>
                    <img onClick={() => handlePopUpDelete()} src={trashIcon} alt='delete student icon' data-testid={'BasicDetails-PopUpDeletebtn'}></img>
                </div>
            </div>
            <div className="studentDetails">
                {
                    studentDataKeys.map((key) => {
                        if (typeof studentSelected[key] !== 'object') {
                            if (key !== 'firstName' && key !== 'lastName'
                                && key !== 'address')
                                return <DetailBlock
                                    blockName={key}
                                    key={key}
                                    blockData={studentSelected[key]}
                                    blockDataAdditional={[]}
                                />;
                            if (key === 'firstName')
                                return <DetailBlock
                                    blockName={'name'}
                                    key={key}
                                    blockData={`${studentSelected[key]} ${studentSelected['lastName']}`}
                                    blockDataAdditional={[]}
                                />;
                            else return '';
                        } else return '';
                    }
                    )
                }

            </div>
            <div className="studentAdditionalDetails">
                {
                    studentDataKeys.map(
                        (key) => {
                            if (typeof studentSelected[key] === 'object')
                                return <DetailBlock
                                    key={key}
                                    blockName={key}
                                    blockData={`${studentSelected[key].name}`}
                                    blockDataAdditional={[{ name: 'phoneNum', value: studentSelected[key]['phoneNum'] }, { name: 'phoneNum', value: studentSelected[key]['email'] }]}
                                />;

                            if (key === 'address')
                                return <DetailBlock
                                    blockName={key}
                                    key={key}
                                    blockData={studentSelected[key]}
                                    blockDataAdditional={[]}
                                />;
                            else return '';
                        }
                    )
                }
            </div>
        </div >
    )
}


export default BasicDetails;