import { useState, useContext } from "react";
import {
    AccountManager, TeamManager
    , InviteMember, SystemPreferences,
    darkModePallete
} from "../../../Admin/AdminImports";
import './UserManagement.css';

const AddMember = () => {
    const [openInvite, setOpenInvite] = useState(false);
    const handleInvite = () => {
        return setOpenInvite(!openInvite);
    }
    const cancelInvite = () => {
        return setOpenInvite(false);
    }

    const { adminView } = useContext(SystemPreferences);
    return (
        <div className={`add-member-container`} style={adminView.viewMode ? { ...darkModePallete.primaryBackground, ...darkModePallete.textColor } : {}}>
            <div className={`add-member-title`} >
                <h4>
                    Team members
                </h4>
                <p>
                    Invite or manage your organization's members.
                </p>
            </div>
            <div className={`add-member-btn-container`}>
                <button className={`add-member-btn`} onClick={handleInvite}> + Add Member </button>
                {openInvite && (
                    <div className="invite-member-popup">
                        <InviteMember closeInvite={cancelInvite} />
                    </div>
                )}
            </div>
        </div>
    )
}

const ManagementNavBar = ({ activeTab, setActiveTab }) => {

    return (
        <div className="mgt-navbar-container">
            <div
                className={`acc-header-container ${activeTab === 'account' ? 'active' : ''}`}
                onClick={() => setActiveTab('account')}
            >
                <a href="#d">Account manager</a>
            </div>
            <div
                className={`user-header-container ${activeTab === 'team' ? 'active' : ''}`}
                onClick={() => setActiveTab('team')}
            >
                <a href="#d">Team manager</a>
            </div>
        </div>
    );
}



function UserManagement() {
    const [activeTab, setActiveTab] = useState('account');
    const { adminView } = useContext(SystemPreferences)
    return (
        <div className={`user-mgt-container`} style={adminView.viewMode ? darkModePallete.textColor : {}}>
            <div className={`title`}  >
                <h2 >User Management</h2>
                <span>Manage your organization</span>
            </div>
            <AddMember />
            <ManagementNavBar activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="content">
                {activeTab === 'account' ? <AccountManager /> : <TeamManager />}
            </div>
        </div>
    )
}
export default UserManagement;