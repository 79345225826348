import "./SideBarView.css";
import { useContext } from "react";
import { SystemPreferences } from "../../../../Admin/AdminImports.js";
function SideBarView() {
    const { adminView, handleAdminView } = useContext(SystemPreferences);
    return <div className="sideBarView">
        <div className="title">
            <h3>Sidebar View</h3>
            <span>Make sidebar hide</span>
        </div>
        <div style={adminView.hideSideBar ? { backgroundColor: "green" } : { backgroundColor: "#fd2020" }}
            className="knob">
            <div
                data-testid={`sideBarView-knob`}
                style={adminView.hideSideBar ? { left: "40px" } : { left: "2px" }}
                onClick={() => handleAdminView("hideSideBar", !adminView.hideSideBar)}
                className="circle"
            >
            </div>
            <span

                className="knobState"
                style={adminView.hideSideBar ?
                    { left: "6px" } :
                    { left: "40px" }}
                data-testid={`sideBarView-knobState`}
            >{adminView.hideSideBar ? "ON" : "OFF"}</span>
        </div>
    </div>
}
export default SideBarView;