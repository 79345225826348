import { useState, useContext } from "react";
import {
    paypal, stripe,
    paymentCards, closeIcon,
} from "../../../../../assetsImports.js"
import { SystemPreferences, darkModePallete } from "../../../../../Admin/AdminImports.js"
import "./PaymentMethodAdd.css";
const CreditCard = () => {
    const { adminView } = useContext(SystemPreferences);
    const [card, setCard] = useState({
        cardNumber: "",
        month: "",
        year: "",
        cvvCvc: "",
        firstName: "",
        lastName: ""
    });
    const formated = (userInput) => {
        let formated = ""
        const cleanedUserInput = userInput.replace(/\s+/g, '');

        if (userInput !== "" && typeof userInput === "string") {

            for (let i = 0; i < cleanedUserInput.length; i++) {
                if ((i + 1) % 4 === 0 && i + 1 < cleanedUserInput.length) {
                    formated += cleanedUserInput[i];
                    if (cleanedUserInput[i + 1] !== ' ') formated += " ";
                } else formated += cleanedUserInput[i];
            }

        }
        return formated
    };
    const handleCardNumber = (e) => setCard((prevCard) => {
        let userInput = !isNaN(e.target.value.replace(/\s+/g, '')) ?
            e.target.value :
            prevCard.cardNumber;
        if (userInput.length <= 19)
            return { ...card, cardNumber: formated(userInput) }
        else return prevCard
    });
    const handleMonth = (e) => setCard((prevCard) => {
        let userInput = !isNaN(e.target.value.replace(/\s+/g, '')) ?
            e.target.value :
            prevCard.cardNumber;
        if (userInput.length <= 2)
            return { ...card, month: userInput }
        else return prevCard
    });
    const handleYear = (e) => setCard((prevCard) => {
        let userInput = !isNaN(e.target.value.replace(/\s+/g, '')) ?
            e.target.value :
            prevCard.cardNumber;
        if (userInput.length <= 2)
            return { ...card, year: userInput }
        else return prevCard
    });
    const handleCvvCvc = (e) => setCard((prevCard) => {
        let userInput = !isNaN(e.target.value.replace(/\s+/g, '')) ?
            e.target.value :
            prevCard.cardNumber;
        if (userInput.length <= 3)
            return { ...card, cvvCvc: userInput }
        else return prevCard
    });
    const handleFirstName = (e) => setCard((prevCard) => {
        let userInput = e.target.value;
        const nameRegex = /^[a-zA-Z]+(?:[-\s][a-zA-Z]+)*$/;

        if (nameRegex.test(userInput) || userInput === "")
            return { ...card, firstName: userInput }
        else return prevCard
    });
    const handleLastName = (e) => setCard((prevCard) => {
        let userInput = e.target.value;
        const nameRegex = /^[a-zA-Z]+(?:[-\s][a-zA-Z]+)*$/;

        if ((nameRegex.test(userInput) || userInput === ""))
            return { ...card, lastName: userInput }
        else return prevCard
    });
    return <form
        className="CreditCard"
        
    >
        <input
            className="cardnumber"
            placeholder="Card Number"
            style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, border: "none" } : {}}
            onChange={handleCardNumber}
            value={card.cardNumber}
        ></input>
        <div className="backCard">
            <input
                placeholder="MM*"
                onChange={handleMonth}
                style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, border: "none" } : {}}

                value={card.month}
            ></input>
            <input
                placeholder="YY*"
                onChange={handleYear}
                style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, border: "none" } : {}}

                value={card.year}
            ></input>
            <input
                placeholder="CVV/CVC*"
                onChange={handleCvvCvc}
                style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, border: "none" } : {}}

                value={card.cvvCvc}
            ></input>
        </div>
        <div className="name"
            style={adminView.viewMode ? { border: "none", padding: "0px" } : {}}
        >
            <input
                placeholder="First Name"
                onChange={handleFirstName}
                style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, border: "none" } : {}}
                value={card.firstName}
            ></input>
            <input
                placeholder="Last Name"
                onChange={handleLastName}
                style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, border: "none" } : {}}
                value={card.lastName}
            ></input>
        </div>

    </form>
}
const PayPal = () => {
    return <div></div>
}
const Stripe = () => {
    return <div></div>
}
function PaymentMethodAdd({ handleAddingPayMth }) {
    const { adminView } = useContext(SystemPreferences);
    const [paymentMeth, setPaymentMeth] = useState([
        { name: "Credit or Debit Card", status: false, component: CreditCard, img: paymentCards },
        { name: "PayPal", status: false, component: PayPal, img: paypal },
        { name: "Stripe", status: false, component: Stripe, img: stripe }
    ]);
    const handlePayementMeth = (name) => setPaymentMeth((prevVal) => {
        const newVal = [...prevVal]
        newVal.forEach((elm) => {
            if (elm.name === name) elm.status = true;
            else elm.status = false;
        });
        return newVal;
    });

    return <div className="PaymentMethodAdd"
    data-testid={`PaymentMethodAdd`}
    style={adminView.viewMode ? { ...darkModePallete.tertiaryBackground, boxShadow: "none" } : {}}>
        <div className="upperSec">
            <h3>
                Payment Methods.
                <span>Choose a payement method</span>
            </h3>
            <img style={adminView.viewMode ? darkModePallete.icon : {}} src={closeIcon} onClick={() => handleAddingPayMth(false)} alt="closing icon"></img>
        </div>
        {
            paymentMeth.map((method) => <div className="methodFields" key={method.name}>
                <div className="method" onClick={() => handlePayementMeth(method.name)}>
                    <h4>
                        <div className="radioBtn">
                            {method.status && <div className="knob"></div>}
                        </div>
                        {method.name}
                    </h4>
                    <img src={method.img} alt={`logo of ${method.name}`}></img>
                </div>
                {method.status && <method.component />}
            </div>
            )
        }
        <button>Continue</button>
    </div>
}

export default PaymentMethodAdd;