import './SideBar.css'
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useContext, useState } from "react";
import { menuIcon } from "../../assetsImports.js"
import { SystemPreferences, darkModePallete } from "../../Admin/AdminImports.js";
import { useEffect } from 'react';
const ViewSideBar = ({ handleShowSideBar, showSideBar }) => {
    const { adminView } = useContext(SystemPreferences)
    return (
        <div
            className='viewSideBar'
            onClick={() => handleShowSideBar()}
            style={showSideBar ? {
                justifyContent: "space-between"
            } : {
                justifyContent: "flex-end"
            }}
        >
            {showSideBar && <img src='' alt='organization logo'></img>}
            <img style={adminView.viewMode ? darkModePallete.icon : {}} src={menuIcon} alt='menu icon'></img>
        </div >)
}

function SideBar({ menu, handleMenu }) {
    const { adminView } = useContext(SystemPreferences);
    const [showSideBar, setShowSideBar] = useState(true);
    useEffect(() => {
        (() => {
            if (!adminView.hideSideBar) setShowSideBar(() => true);
            if (adminView.hideSideBar) setShowSideBar(() => false)
        })()
    }, [adminView.hideSideBar]);

    const handleShowSideBar = () => setShowSideBar((prevVal) => !prevVal);
    return (
        <nav
            className="SideBar"
            style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.shadow } : {}}
        >
            {adminView.hideSideBar && <ViewSideBar handleShowSideBar={handleShowSideBar} showSideBar={showSideBar} />}
            {showSideBar && <div className='sideBarLinks'>
                {
                    menu.map(
                        (option, i) =>
                            <div className={option['name'] !== 'Logout' ? 'link' : 'link logout'}
                                key={i.toString()}
                                style={{ animation: `linkAppear ${(i * 0.2)}s forwards ease-in-out` }}
                            >
                                <NavLink
                                    style={adminView.viewMode ? { ...darkModePallete.textColor, cursor: "pointer" } : {}}
                                    to={option['link']}
                                    data-testid="options-link"
                                    className={'link'}

                                    onClick={() => handleMenu(option['name'])}
                                >
                                    <img style={adminView.viewMode ? darkModePallete.icon : {}} src={option['icon']} alt={`${option['name']} icon`}></img>
                                    <span data-testid={`option-${option['name']}`}>{option['name']}</span>
                                </NavLink>
                            </div>

                    )
                }
            </div>}

        </nav >
    );
}

SideBar.propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    handleMenu: PropTypes.func.isRequired,
};

export default SideBar;