import { useState } from "react";
import "./FontSize.css"
import { useContext } from "react";
import { SystemPreferences } from "../../../../Admin/AdminImports.js";
import { useEffect } from "react";
function FontSize() {
    const { adminView, handleAdminView } = useContext(SystemPreferences);
    const currentFontSize = adminView.fontSize.filter(({ status }) => status)[0];
    const [testSize, setTestSize] = useState(currentFontSize.value);
    useEffect(() => {
        const currentFontSize = adminView.fontSize.filter(({ status }) => status)[0];
        setTestSize(() => currentFontSize.value)
    }, [adminView])
    return (<div className="FontSize">
        <div className="title">
            <h3>Font Size</h3>
            <span>Resize font size</span>
        </div>
        <div className="testView">
            <span className="preview" style={{ fontSize: testSize }}>Preview</span>
        </div>
        <div className="sizes">
            {
                adminView.fontSize.map((size) => <div
                    className="sizeOption"
                    key={size.name}
                    onClick={() => handleAdminView("fontSize", size.name)}
                    style={size.status ? {
                        border: "2px solid #0099ff",
                        color: "#0099ff"
                    } : {}}
                >
                    <span>{size.name}</span>
                </div>)
            }
        </div>
    </div>)
}
export default FontSize;