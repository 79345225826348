import { useState, useContext, useEffect } from 'react';
import { SystemPreferences, darkModePallete } from "../../../Admin/AdminImports.js";
import { addIcon, closeIcon } from '../../../assetsImports.js'
import './PopUpEdit.css';


const StringInput = ({ name, value }) => {
    const { adminView } = useContext(SystemPreferences);
    const [fieldVal, setFieldVal] = useState(value);
    const handleFieldVal = (e) => setFieldVal(() => e.target.value);
    return < label >
        <span>
            {name}
        </span>
        <input
            style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.textColor, borderColor: "transparent" } : {}}
            type='text'
            value={fieldVal}
            onChange={handleFieldVal}
        >
        </input>
    </label >
}
const BooleanInput = ({ name, value }) => {
    const [knob, setKnob] = useState(value)
    const handleKnob = () => setKnob((prevVal) => !prevVal)
    return (
        <label>
            <span>
                {name}
            </span>
            <div
                style={
                    knob ?
                        { backgroundColor: "green" } :
                        { backgroundColor: "#fd2020" }
                }
                className='knob'>
                <div
                    style={knob ? { left: "40px" } : { left: "2px" }}
                    className="circle"
                    onClick={() => handleKnob()}
                ></div>

            </div>
        </label>
    )
};
const DateInput = ({ name, value }) => {
    const { adminView } = useContext(SystemPreferences);
    const [fieldVal, setFieldVal] = useState(value);
    const handleFieldVal = (e) => setFieldVal(() => e.target.value);
    return <label>
        <span>
            {name}
        </span>
        <input
            style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.textColor, borderColor: "transparent" } : {}}
            type="date"
            value={fieldVal}
            onChange={handleFieldVal}
        >
        </input>
    </label>
};
const NumberInput = ({ name, value }) => {
    const { adminView } = useContext(SystemPreferences);
    return <label>
        <span>
            {name}
        </span>
        <input
            style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.textColor, borderColor: "transparent" } : {}}
            type="number"
        >
        </input>
    </label>
};
const ImageInput = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const { adminView } = useContext(SystemPreferences);
    const handleFileChange = (event) => {
        const file = event.target.files[0];

        setSelectedFile(event.target.files[0]);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else setPreviewImage(null);

    };
    const handleDelImage = () => {
        setPreviewImage(null);
        setSelectedFile(null);
    }
    const handleAddImage = () => {
        document.getElementById("imageInput").click();
    }
    return (
        <div>
            <span className='imgField'>
            </span>
            <div className='FileInput'>
                <img src={selectedFile ? previewImage : addIcon}
                    style={adminView.viewMode ? { filter: "brightness(1000%) contrast(100%) hue-rotate(0deg)" } : {}}
                    alt={selectedFile ? "Student Photo" : 'add icon'}></img>
                <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />
                <div
                    onClick={() => handleAddImage()}
                    className='addImg'
                    style={adminView.viewMode ? darkModePallete.btnBlue : {}}
                >Update Image</div>
                <div
                    onClick={() => handleDelImage()}
                    className='delImg'
                    style={adminView.viewMode ? darkModePallete.textColor : {}}
                >Remove Image</div>
            </div>
        </div>
    )
};
const SubFields = ({ name, value }) => {
    return (
        <div className='SubField'>
            {name}
            <div className='SubFields' >
                {
                    value.map((field) => {
                        switch (field.type) {
                            case "file":
                                return <ImageInput key={field.name} value={field.value} />
                            case "string":
                                return <StringInput key={field.name} name={nameFormater(field.name)} value={field.value} />
                            case "number":
                                return <NumberInput key={field.name} name={nameFormater(field.name)} value={field.value} />
                            case "boolean":
                                return <BooleanInput key={field.name} name={nameFormater(field.name)} value={field.value} />
                            default:
                                return <></>;
                        }
                    })
                }
            </div>
        </div>
    )
}

const fieldExtractor = (object) => {
    const fields = [];
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    for (let key in object) {
        if (object.hasOwnProperty(key))
            if (!Array.isArray(object[key])) {
                const isValidDate = dateRegex.test(object[key]);
                let type;
                if (isValidDate) type = "date";
                else if (typeof object[key] === "object") type = "array";
                else if (key.toLowerCase().includes("photo") || key.toLowerCase().includes("profile")) type = "file";
                else type = typeof object[key];

                fields.push(
                    {
                        name: key,
                        value: type === "array" ? fieldExtractor(object[key]) : object[key],
                        type: type
                    }
                );
            }
    }
    return fields
}
function isUppercase(letter) {
    if (letter.length !== 1) {
        return false;
    }
    return letter === letter.toUpperCase();
}
const nameFormater = (fieldName) => {
    let newFormat = "";
    for (let i = 0; i < fieldName.length; i++) {
        if (i === 0) newFormat += fieldName[i].toUpperCase();
        else if (isUppercase(fieldName[i])) newFormat += ` ${fieldName[i]}`
        else newFormat += fieldName[i];
    }
    return newFormat;
}

function PopUpEdit({
    data,
    handlePopUpEdit,
}) {
    const fields = fieldExtractor(data);
    const { adminView } = useContext(SystemPreferences)
    useEffect(() => {

        const popUp = document.getElementById("PopUpEditAdd");
        const handleClick = (event) => {
            if (popUp && !popUp.contains(event.target))
                handlePopUpEdit()
        }
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [handlePopUpEdit]);
    return (
        <form
            className="PopUpEditAdd"
            id='PopUpEditAdd'
            data-testid={`popUpEdit-BasicDetails`}
            style={adminView.viewMode ? { ...darkModePallete.tertiaryBackground, color: "white", boxShadow: `none` } : {}}
        >
            <img src={closeIcon} 
            data-testid={`popUpEdit-close-icon-BasicDetails`}
            style={adminView.viewMode ? darkModePallete.icon : {}} 
            className='closeIcon' 
            alt="icon to close" 
            onClick={() => { handlePopUpEdit() }}></img>

            <div className='fields'>

                {
                    fields.map((field) => {
                        switch (field.type) {
                            case "file":
                                return <ImageInput key={field.name} value={field.value} />
                            case "string":
                                return <StringInput key={field.name} name={nameFormater(field.name)} value={field.value} />
                            case "boolean":
                                return <BooleanInput key={field.name} name={nameFormater(field.name)} value={field.value} />
                            case "date":
                                return <DateInput key={field.name} name={nameFormater(field.name)} value={field.value} />
                            case "number":
                                return <NumberInput key={field.name} name={nameFormater(field.name)} value={field.value} />
                            case "array":
                                return <SubFields key={field.name} name={nameFormater(field.name)} value={field.value} />
                            default:
                                return <></>;
                        }
                    })
                }
            </div>
            <div className='saveDiscard'>
                <button className='save' style={adminView.viewMode ? darkModePallete.btnBlue : {}} onClick={(e) => { e.preventDefault() }}>Save</button>
                <button className='discard' style={adminView.viewMode ? darkModePallete.textColor : {}} onClick={(e) => { e.preventDefault(); handlePopUpEdit() }}>Discard</button>
            </div>
        </form>
    );
}

export default PopUpEdit;