import { addIcon } from "../../../../assetsImports.js"
import {
    PaymentMethodAdd
} from "../../../../Admin/AdminImports.js"
import "./PayementMethod.css"
import { useState } from "react";

function AddPayement({ handleAddingPayMth }) {
    return <div className="addPaymMethod" >
        <div className="add"
            onClick={() => handleAddingPayMth(true)}
            data-testid={`AddPayment-addBtn-newMeth`}
        ><img src={addIcon} alt="add icon"></img></div>
        <span>Add a method of payement</span>
    </div >;
}
const AvailablePayements = ({
    lspaymentMethods, handleAddingPayMth,
    handleRemovePaymentMethods
}) =>
    <div className="payementMethodsAvailable">
        {
            lspaymentMethods.map((method, i) => {
                let info = "";
                if (method["cardNumber"])
                    info = method["cardNumber"].toString().slice(-4)
                else {
                    let atIndex = method["email"].indexOf("@");
                    let firstTwoLetters = method["email"].slice(0, atIndex - 2);
                    info = `${firstTwoLetters}***${method["email"].slice(atIndex)}`;
                }

                return <div
                    key={i.toString()}
                    className="method"
                    data-testid={`available-paymentMethod`}>
                    <div className="payementLogo">
                        <img src={method.img} alt="payement logo"></img>
                    </div>
                    <div className="info">{method["cardNumber"] ? "Ending with" : "Account"} {info}</div>
                    <button data-testid={`availablePayments-removeBtn`} onClick={() => handleRemovePaymentMethods(method.type)}>Remove</button>
                </div>
            }
            )
        }
        {lspaymentMethods.length < 3 && <AddPayement handleAddingPayMth={handleAddingPayMth} />}
    </div>;
function PayementMethod({ lspaymentMethods, handleRemovePaymentMethods }) {
    const [addingPayMth, setaddingPayMth] = useState(false);
    const handleAddingPayMth = (state) => setaddingPayMth(state)
    return <div className="PayementMethod">
        <div className="title">
            <h4>Payment Method</h4>
            <span>Manage Billing information</span>
        </div>
        {
            lspaymentMethods.length > 0 ?
                (<AvailablePayements lspaymentMethods={lspaymentMethods} handleAddingPayMth={handleAddingPayMth} handleRemovePaymentMethods={handleRemovePaymentMethods} />) :
                (<AddPayement handleAddingPayMth={handleAddingPayMth} />)
        }
        {addingPayMth && <PaymentMethodAdd handleAddingPayMth={handleAddingPayMth} />}
    </div>
}

export default PayementMethod;