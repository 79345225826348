import { useState } from "react";
import { createContext } from "react";

export const SystemPreferences = createContext();
export const SystemPreferencesProvider = ({ children }) => {
    const [adminView, setAdminView] = useState({
        viewMode: false,
        hideSideBar: false,
        fontSize: [{ name: "Small", value: "16px", status: true },
        { name: "Medium", value: "24px", status: false },
        { name: "Large", value: "32px", status: false }]
    });
    const handleAdminView = (settingName, value) => setAdminView((prevVal) => {
        const newAdminView = { ...prevVal };
        if (settingName !== "fontSize")
            newAdminView[settingName] = value
        if (settingName === "fontSize")
            newAdminView[settingName].forEach((size) => {
                if (size.name === value) size.status = true;
                else size.status = false;
            })
        return newAdminView
    });
    return (
        <SystemPreferences.Provider value={{ adminView, handleAdminView }}>
            {children}
        </SystemPreferences.Provider>
    )
}