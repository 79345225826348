import {
    dateDisplay
} from "../../../../Admin/AdminImports.js"
import "./Cancelation.css";
const Cancelation = ({ plan }) => {
    return <div className="Cancelation">
        <span>Your Plan</span>
        <h2>{plan.name}</h2>
        <span>Valid Till: {dateDisplay(plan.expiryDate)}</span>
        <button>Cancel Subscription</button>
    </div>
}
export default Cancelation