import React, { useState } from 'react';
import './RoleChange.css';


const RoleChange = ({ userRoles, onSave, onCancel }) => {
    const [selectedRoles, setSelectedRoles] = useState([...userRoles]);

    const handleRoleChange = (role) => {
        if (selectedRoles.includes(role)) {
            // remove selected role
            setSelectedRoles(selectedRoles.filter((r) => r !== role));
        } else {
            // Add non-selected role
            setSelectedRoles([...selectedRoles, role]);
        }
    };

    return (
        <div className="role-change-overlay">
            <div className="role-change-box">
                <h3>Change Roles</h3>
                <div className="roles-list">
                    {['Admin', 'Student', 'SuperAdmin', 'Teacher'].map((role, index) => (
                        <div key={index} className="role-item">
                            <input
                                type="checkbox"
                                id={role}
                                checked={selectedRoles.includes(role)}
                                onChange={() => handleRoleChange(role)}
                            />
                            <label htmlFor={role}>{role}</label>
                        </div>
                    ))}
                </div>
                <div className="role-change-buttons">
                    <button onClick={onCancel}>Cancel</button>
                    <button onClick={() => onSave(selectedRoles)} className="save-button">Save</button>
                </div>
            </div>
        </div>
    );
};

export default RoleChange;
