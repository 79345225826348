import { useContext } from 'react';
import { teacherContext, SystemPreferences, darkModePallete } from '../../../Admin/AdminImports';
import './TeachersList.css'

function TeachersList() {
    const { handleSetSelTeach, currentPosts } = useContext(teacherContext);
    const { adminView } = useContext(SystemPreferences);

    const handleTeacherSelect = (index) => {
        handleSetSelTeach(index);
    };


    return (
        <div className={`teachers-list-container`} data-testid={`teacherList`}>
            {
                currentPosts.map((teacher, id) => (

                    <div className={`teachers-list-grid`} key={id}
                        style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.shadow, ...darkModePallete.textColor } : {}}
                    >
                        <div className={`teachers-profile-pic`} >
                            <img alt="teacher's profile" src={teacher.photo} data-testid={`teachers-profile-pic`}></img>
                        </div>

                        <div className={`teachers-info`} >
                            <div className={`teachers-fullname`}>
                                <h3>{teacher.firstName} {teacher.lastName}</h3>
                            </div>

                            <div className={`teachers-disciplines`} >
                                {teacher.discipline.map((subject) => (
                                    <span className={`course`} key={subject}>{subject}</span>
                                ))}
                            </div>

                            <div className={`teachers-profile-link`} onClick={() => handleTeacherSelect(id)}
                               
                            >
                                <p  style={adminView.viewMode ? { color: "#3fa9f5" } : {}}>View Profile</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default TeachersList