import { useContext } from "react";
import { darkModePallete, SystemPreferences, StudentContext } from "../../../../Admin/AdminImports"
const UpperSec = () => {
    const { adminView } = useContext(SystemPreferences);
    const { studentSelected } = useContext(StudentContext)
    return (
        <div className='UpperSec' style={adminView.viewMode ? darkModePallete.btnBlue : {}}>
            <img data-testid={`UpperSec-${studentSelected['firstName']}`} src={studentSelected['photo']} alt='studentImage'></img>
            <div className='shortDescript'>
                <span className='studentName' data-testid={`UpperSec-${studentSelected['firstName']}-${studentSelected['stu_id']}`}>{`${studentSelected['firstName']} ${studentSelected['lastName']}`}</span>
                <span className='class'>Class <span data-testid={`UpperSec-${studentSelected['class']}`}>{studentSelected['class']}</span></span>
            </div>
        </div>
    )
}

export default UpperSec;