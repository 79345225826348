import './DeleteAccount.css';

const DeleteAccount = ( {onDelete, onCancel }) => {

    return (
      <div className="delete-account-overlay">
        <div className="delete-account-box">
          <p>Are you sure you want to delete this account?</p>
          <div className="delete-account-buttons">
            <button onClick={onDelete}>Cancel</button>
            <button onClick={onCancel} className="delete-button">Delete</button>
          </div>
        </div>
      </div>
    );
};

export default DeleteAccount