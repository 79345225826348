import { useEffect, useContext } from "react"
import { SystemPreferences, darkModePallete } from "../../../Admin/AdminImports.js"
import { closeIcon, closeIconRed } from '../../../assetsImports'
import './PopUpDelete.css'

function PopUpDelete(
    {
        data,
        handlePopUpDelete
    }
) {
    const { adminView } = useContext(SystemPreferences)

    const handleDeletion = () => {
        console.log("delete", data)
    }
    useEffect(() => {
        const popUp = document.getElementById("popUpDelete");
        const handleClick = (event) => {
            if (popUp && !popUp.contains(event.target))
                handlePopUpDelete()
        }
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [handlePopUpDelete]);
    return (
        <div
            id="popUpDelete"
            className="popUpDelete"
            data-testid={`popUpDelete-BasicDetails`}
            style={adminView.viewMode ? { ...darkModePallete.tertiaryBackground, ...darkModePallete.textColor, boxShadow: "none" } : {}}
        >

            <img
                className='closeBtn'
                style={adminView.viewMode ? darkModePallete.icon : {}}
                onClick={() => handlePopUpDelete()}
                data-testid={`popUpDelete-close-icon-BasicDetails`}
                src={closeIcon} alt="closewindow icon"></img>
            <img
                className="dangerSign"
                src={closeIconRed} alt="deletion icon"></img>
            <h1>Are you sure?</h1>
            <p>Do you really want to delete the student? This process cannot be undone.</p>
            <div className="cancel-delete-container">
                <div className='cancelBtn'
                    onClick={() => handlePopUpDelete()}
                >Cancel</div>
                <div
                    className='deleteBtn'
                    onClick={() => handleDeletion()}
                >Delete</div>
            </div>

        </div>
    );
}

export default PopUpDelete;