import { useState, useCallback } from "react";
import { StudentContext } from "./context/studentContext";
import { Header, StudentList, AdminStudentDetails, AdminStudentAddExport } from '../../Admin/AdminImports';
import './AdminStudentSec.css';
import { data } from "../Dashboard/dummyData";


function Students() {
    const [studentSelected, setStudentSelected] = useState(data[0]);
    const handleStudentSelected = (first_Name, last_Name) => {
        let newStudentSelected = {};

        data.forEach(({ firstName, lastName }, i) => {

            if (firstName === first_Name && lastName === last_Name) {

                newStudentSelected = data[i];
            }
        })
        setStudentSelected({ ...newStudentSelected })
    };

    const [toExportVal, setToExportVal] = useState([]);
    const handleToExportVal = useCallback((checkedItems) => setToExportVal([...checkedItems]), []);
    const [exportAll, setExportAll] = useState(false);
    const handleExportAll = (val) => setExportAll(() => val)

    return (
        <div data-testid="Students" className="Students">
            <Header menuName='Students' />
            <StudentContext.Provider value={{ data, handleStudentSelected, studentSelected, handleToExportVal, toExportVal, handleExportAll, exportAll }} >
                <div className="studentListData-container">
                    <div className="container-leftSide">
                        <AdminStudentAddExport />
                        <StudentList />
                    </div>
                    <AdminStudentDetails />
                </div>
            </StudentContext.Provider>
        </div>
    );
}

export default Students;