export const event = [
    {
        id: "advweq34cq3cqwefqwef",
        title: 'Birthday',
        people: ['dummy1@gmail.com'],
        date: '2024/02/24',
        startTime: '09:00',
        endTime: '10:00',
        reminder: '15min',
        repeat: { status: false, date: '2024/02/24' },
        location: '123 Main St, City1, Country1',
        status: 'completed',
        attachment: 'from local',
        description: 'Text entered in the email box 1'
    },
    {
        id: "advweq34cq3cqwefqngr",
        title: 'Meeting about refact',
        people: ['dummy2@gmail.com', 'dummy3@gmail.com'],
        date: '2024/03/07',
        startTime: '14:30',
        endTime: '16:00',
        reminder: '30min',
        repeat: { status: true, date: '2024/03/07' },
        location: '456 Elm St, City2, Country2',
        status: 'pending',
        attachment: 'from local',
        description: 'Text entered in the email box 2'
    },
    {
        id: "advweq34cq3cqwefqhrh3rh",
        title: 'Sales',
        people: ['dummy4@gmail.com', 'dummy5@gmail.com', 'dummy6@gmail.com'],
        date: '2024/02/12',
        startTime: '18:00',
        endTime: '20:00',
        reminder: '1h',
        repeat: { status: true, date: '2024/02/26' },
        location: '789 Oak St, City3, Country3',
        status: 'in progress',
        attachment: 'from local',
        description: 'Text entered in the email box 3'
    },
    {
        id: "advweq34cq3cqwefqasdqwdq",
        title: 'Interview',
        people: ['dummy7@gmail.com'],
        date: '2024/02/07',
        startTime: '11:30',
        endTime: '12:30',
        reminder: '15min',
        repeat: { status: false, date: '2024/02/27' },
        location: '321 Maple Ave, City4, Country4',
        status: 'completed',
        attachment: 'from local',
        description: 'Text entered in the email box 4'
    },
    {
        id: "advweq34cq3cqwefqsdf2ga",
        title: 'Assignemts',
        people: ['dummy8@gmail.com', 'dummy9@gmail.com'],
        date: '2024/02/28',
        startTime: '16:00',
        endTime: '17:30',
        reminder: '30min',
        repeat: { status: true, date: '2024/02/28' },
        location: '654 Pine Rd, City5, Country5',
        status: 'pending',
        attachment: 'from local',
        description: 'Text entered in the email box 5'
    },
    {
        id: "advweq34cq3cqwefqsdf2",
        title: '10 years celebrations',
        people: ['dummy10@gmail.com', 'dummy11@gmail.com', 'dummy12@gmail.com'],
        date: '2024/04/02',
        startTime: '09:00',
        endTime: '11:00',
        reminder: '1h',
        repeat: { status: false, date: '2024/02/29' },
        location: '987 Cedar Ln, City6, Country6',
        status: 'in progress',
        attachment: 'from local',
        description: 'Text entered in the email box 6'
    },
    {
        id: "advweq34cq3cqwefq2ee",
        title: 'Extra curriculum activities',
        people: ['dummy13@gmail.com'],
        date: '2024/03/01',
        startTime: '14:00',
        endTime: '15:30',
        reminder: '15min',
        repeat: { status: false, date: '2024/03/01' },
        location: '258 Oak St, City7, Country7',
        status: 'completed',
        attachment: 'from local',
        description: 'Text entered in the email box 7'
    },
    {
        id: "advweq34cq3cqwefqsdfw",
        title: 'Finances',
        people: ['dummy21@gmail.com'],
        date: '2024/04/24',
        startTime: '09:00',
        endTime: '10:00',
        reminder: '15min',
        repeat: { status: true, date: '2024/02/24' },
        location: '123 Main St, City1, Country1',
        status: 'completed',
        attachment: 'from local',
        description: 'Text entered in the email box 1'
    },
    {
        id: "advweq34cq3cqwefqfs",
        title: 'Entertainement',
        people: ['dummy24@gmail.com', 'dummy33@gmail.com'],
        date: '2024/02/25',
        startTime: '14:30',
        endTime: '16:00',
        reminder: '30min',
        repeat: { status: true, date: '2024/02/25' },
        location: '456 Elm St, City2, Country2',
        status: 'pending',
        attachment: 'from local',
        description: 'Text entered in the email box 2'
    },
    {
        id: "advweq34cq3cqwefqga",
        title: 'Detention',
        people: ['dummy42@gmail.com', 'dummy55@gmail.com', 'dummy36@gmail.com'],
        date: '2024/03/26',
        startTime: '18:00',
        endTime: '20:00',
        reminder: '1h',
        repeat: { status: true, date: '2024/02/26' },
        location: '789 Oak St, City3, Country3',
        status: 'in progress',
        attachment: 'from local',
        description: 'Text entered in the email box 3'
    },
];