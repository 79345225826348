import './DeleteConfirmation.css';

const DeleteConfirmation = ({ onDelete, onCancel }) => {

    return (
      <div className="confirmation-overlay">
        <div className="confirmation-box">
          <p>Are you sure you want to delete?</p>
          <div className="confirmation-buttons">
            <button onClick={onDelete}>Cancel</button>
            <button onClick={onCancel} className="delete-button">Delete</button>
          </div>
        </div>
      </div>
    );
  };

export default DeleteConfirmation