import { useContext, useEffect, useState, useRef } from 'react';
import './Calendar.css';
import {
    addingDaysFromPrevOrNextMonth, filterDayIntoDayColumn, getDaysInMonth, todayDate, convertDayOfWeekToNumber
    , CalendarContext, EventContext, FloatViewEvent, SystemPreferences, darkModePallete
} from "../../../Admin/AdminImports";

import repeatIcon from "../../../icons/repeatIcon.svg";
import repeatIconDark from "../../../icons/repeatIconDark.svg";

const EventCard = ({ dayBlockData }) => {
    const [viewEvent, setViewEvent] = useState(false);
    const today = new Date();
    const isPast = today.getTime() > new Date(dayBlockData.date).getTime();
    const { adminView } = useContext(SystemPreferences)
    const cardStyle = isPast ? { backgroundColor: "transparent", color: "#6d6d6d", border: "#0078d5 solid 1px" } : {};
    const whiteRectStyle = isPast ? { backgroundColor: "transparent", height: "inherit", borderRight: "#0078d5 solid 1px" } : {};
    const titleStyle = !dayBlockData.repeat.status ? { "flexGrow": "1" } : {};

    const eventCard = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (eventCard.current && !eventCard.current.contains(event.target)) {
                setViewEvent(false);
            }
        };

        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [eventCard]);

    const handleViewEvent = (e) => {
        const parentElementName = e.target.parentElement.className;
        if (parentElementName.includes("EventCard")) {
            setViewEvent(prevViewEvent => { return !prevViewEvent; });
        }
    }
    const handleFloatingView = () => setViewEvent(prevViewEvent => { return !prevViewEvent; });

    return <div
        onClick={(e) => handleViewEvent(e)}
        className={"EventCard " + dayBlockData.date}
        style={cardStyle}
        ref={eventCard}

    >
        <div className='whiteRect' style={whiteRectStyle}></div>
        <div
            data-testid={`EventCard-${dayBlockData.title}`}
            className='title'
            style={adminView.viewMode ? darkModePallete.textColor : titleStyle}>
            {dayBlockData.title}
        </div>
        {dayBlockData.repeat.status && <img src={isPast ? repeatIconDark : repeatIcon} style={adminView.viewMode ? darkModePallete.icon : {}} alt='repeat icon'></img>}
        <FloatViewEvent viewEvent={viewEvent} dayBlockData={dayBlockData} handleFloatingView={handleFloatingView} />
    </div >;
}
const DayBlock = ({ day }) => {
    const today = todayDate();
    const { adminView } = useContext(SystemPreferences)
    const { month, year } = useContext(CalendarContext);
    const { events } = useContext(EventContext);
    const isToday = today.day === day.day && today.month + 1 === day.month && today.year === year;

    const dayBlockData = events.find(({ date }) => new Date(`${year}/${day['month']}/${day['day']}`).getTime() === new Date(date).getTime());
    const isTodayStyling = {
        backgroundColor: "#0078d5",
        borderRadius: "50%",
    };
    const pastMonth = adminView.viewMode ? { backgroundColor: "#191919" } : { backgroundColor: "#f8f8f8" }
    const curMonth = adminView.viewMode ? darkModePallete.secondaryBackground : {}
    return <div
        className='DayBlock'
        style={month !== day.month ? pastMonth : curMonth}
    >
        <div className='day' style={isToday ? isTodayStyling : {}} >
            <span style={isToday ? { color: "white" } :
                adminView.viewMode ? darkModePallete.textColor : {}}
                data-testid={`DayBlock-day-${day['month']}-${day['day']}-${day['nameOfTheDay']}`}>{day['day']}</span>
        </div>
        {dayBlockData && <EventCard dayBlockData={dayBlockData} />}
    </div>
}

const DayColumn = ({ columnName, listOfDays }) => {
    const { month, year } = useContext(CalendarContext);
    const today = todayDate();
    const dateInLetters = convertDayOfWeekToNumber(columnName);
    const isToday = today.date === dateInLetters && today.month + 1 === month && today.year === year;
    return <div className='DayColumn'>
        <h3 style={isToday ? { color: "#0478d4" } : {}}>{columnName}</h3>
        <div className='listOfDays'>
            {listOfDays.map((day, index) =>
                <DayBlock key={`${index}`} day={day} />
            )}
        </div>
    </div>
}

function Calendar() {
    const { month, year } = useContext(CalendarContext);
    const { adminView } = useContext(SystemPreferences)
    const daysInMonth = getDaysInMonth(year, month);
    const daysInMonthColumFilter = filterDayIntoDayColumn(daysInMonth);
    const finalCalendar = addingDaysFromPrevOrNextMonth(daysInMonthColumFilter, year, month);

    return (
        <section className='CalendarComp' >
            <div className='Calendar' style={adminView.viewMode ? darkModePallete.shadow : {}}>
                {
                    finalCalendar.map((column, index) =>
                        <DayColumn
                            key={`Calendar-${column['name']}-${index}`}
                            columnName={column['name']}
                            listOfDays={column.days}
                        />)
                }
            </div>
        </section>
    );
}

export default Calendar;