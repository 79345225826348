import { NavLink } from "react-router-dom";
import graduationIcon from '../icons/graduationIcon.svg';
import identityCardIcon from '../icons/identityCardIcon.svg';
import groupIcon from '../icons/groupIcon.svg';
import managementIcon from '../icons/managementIcon.svg';
import registerIcon from '../icons/registerIcon.svg';
import packageSearchIcon from '../icons/packageSearchIcon.svg';
import './Home.css';


function NavBar() {
    return (
        <nav className="navigation">
            <div className="logo">
                <img src="" alt="SMS logo"></img>
                <h1>SMS</h1>
            </div>
            <div className="navLinks">
                <NavLink className='link'>Home</NavLink>
                <NavLink className='link'>About Us</NavLink>
                <NavLink className='link'>Contact</NavLink>
                <NavLink className='loginBtn'>Login</NavLink>
                <NavLink className='registerBtn'>Register</NavLink>
            </div>
        </nav>
    )
}
function Header() {
    return (
        <header className="header">

            <div className="leftSec">
                <div className="title-subtitle">
                    <h1>Empowering <span className="focusedWord">Education</span> Management</h1>
                    <p>Streamline operations, enhance communication, and improve student performance.</p>
                </div>
                <NavLink className='getStartedBtn'>Get Started</NavLink>
            </div>

            <div className="rightSec">
                <div className="floatingCard card1">
                    <img src={graduationIcon} alt="icon"></img>
                    <div className="card-info">
                        <span className="card-title">Student</span>
                        <span className="sub-Text">Records</span>
                    </div>
                </div>
                <div className="floatingCard card2">
                    <img src={groupIcon} alt="icon"></img>
                    <div className="card-info">
                        <span className="card-title">Teachers</span>
                        <span className="sub-Text">Lesson planing</span>
                    </div>
                </div>
                <div className="floatingCard card3">
                    <img src={identityCardIcon} alt="icon"></img>
                    <div className="card-info">
                        <span className="card-title">Administration</span>
                        <span className="sub-Text">Presence tracking</span>
                    </div>
                </div>
                <div className="nonfilled-Circle"></div>
                <div className="filled-circle"> </div>
            </div>
        </header>
    )
}
function HowItWorks() {
    return (
        <section className="HowItWorks">
            <h2>How it works</h2>
            <div className="steps">
                <div className="step">
                    <img src={registerIcon} alt="icon"></img>
                    <h4>Register</h4>
                    <p>Create your account by clicking the registry button.</p>
                </div>
                <div className="dashes"></div>
                <div className="step">
                    <img src={packageSearchIcon} alt="icon"></img>
                    <h4>Select a Package</h4>
                    <p>Explore various packages, and select the most suitable.</p>
                </div>
                <div className="dashes"></div>
                <div className="step">
                    <img src={managementIcon} alt="icon"></img>
                    <h4>Start Managing</h4>
                    <p>Congratulations, you can start working more efficiently.</p>
                </div>
            </div>
        </section>
    )
}

function Home() {
    return (
        <div className="Home">
            <NavBar />
            <Header />
            <HowItWorks />
        </div>
    );
}

export default Home;