import PropTypes from 'prop-types';
import './AdminAttendanceFlowChart.css';
import {
    XAxis, YAxis, CartesianGrid, Line, Tooltip, AreaChart, Area,ResponsiveContainer
} from 'recharts';
import { useContext } from "react"
import { SystemPreferences, darkModePallete } from "../../../Admin/AdminImports.js"

const attendanceOfStudentsPerDay = (studentList, month, year) => {
    const repeatedDates = [];
    const presenceDates = [];
    const unrepeatedDates = [];
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    //Getting all of the students' attendance
    studentList.forEach((student) => {
        repeatedDates.push(...student['presence'])
    });

    //getting all the dates without repeating any
    repeatedDates.forEach(({ date, presence }) => {

        const dateFormated = new Date(date);
        const isMonthSelected = month === monthNames[dateFormated.getMonth()];
        const isYearSelected = year === dateFormated.getFullYear();
        const isInPresenceDates = !presenceDates.includes(date);

        if (isMonthSelected && isYearSelected) {
            if (isInPresenceDates) {
                presenceDates.push(date);
                unrepeatedDates.push({ date, students: 1 });
            } else if (presence) {
                const index = presenceDates.indexOf(date);
                unrepeatedDates[index]['students'] += 1;
            }

        }
    });

    return unrepeatedDates;
}
const TitleArea = ({ title, handleTimeFrameChart, timeFrameChart, timeFramesAvailable }) => {
    const { adminView } = useContext(SystemPreferences)
    return (
        <div className='upperPart'>
            <span className='title' style={adminView.viewMode ? darkModePallete.textColor : {}}>{title}</span>
            <div className='TimeFrame'>
                <div className='monthSec' style={adminView.viewMode ? darkModePallete.textColor : {}}>
                    {timeFrameChart['month']}

                    <div className='months'>
                        {
                            timeFramesAvailable['months'].map(
                                (month, i) =>
                                    <span
                                        key={i.toString()}
                                        className='month'
                                        onClick={() => handleTimeFrameChart({ month: month, year: timeFrameChart['year'] })}
                                    >{month}</span>
                            )
                        }
                    </div>

                </div>
                <div className='yearSec' style={adminView.viewMode ? darkModePallete.textColor : {}}>
                    {timeFrameChart['year']}
                    <div className='years'>
                        {
                            timeFramesAvailable['years'].map(
                                (year, i) =>
                                    <span
                                        key={i.toString()}
                                        className='year'
                                        onClick={() => handleTimeFrameChart({ month: timeFrameChart['month'], year: year })}
                                    >{year}</span>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
function AdminAttendanceFlowChart1({
    title, studentList, height,
    handleTimeFrameChart, timeFrameChart, timeFramesAvailable
}) {

    const { adminView } = useContext(SystemPreferences)
    const data = attendanceOfStudentsPerDay(studentList, timeFrameChart['month'], timeFrameChart['year']);
    const CustomYAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <text x={x} y={y} dx={-16} dy={4}
                className='yAxisTick'
                data-testid="yAxisTestId"
                fill={adminView.viewMode ? "rgb(255, 255, 255)" : ""}

                textAnchor="middle">
                {payload.value}
            </text>
        );
    };
    const CustomXAxistick = (props) => {
        const { x, y, payload } = props;
        const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const dateFormated = new Date(payload.value);
        const day = days[dateFormated.getDay()];
        const date = dateFormated.getDate();
        return (
            <text x={x} y={y} dx={0} dy={15}
                fill={adminView.viewMode ? "rgb(255, 255, 255)" : ""}
                textAnchor="middle">
                {`${day} ${date}`}
            </text>
        );
    };
    const desiredTickCount = 6; // The desired number of ticks on the x-axis
    const dataLength = data.length;
    const interval = Math.ceil(dataLength / desiredTickCount);

    return (
        <div
            id="AdminAttendanceFlowChart"
            className='AdminAttendanceFlowChart'
            style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.shadow } : {}}
        >
            <TitleArea
                title={title}
                handleTimeFrameChart={handleTimeFrameChart}
                timeFrameChart={timeFrameChart}
                timeFramesAvailable={timeFramesAvailable}
            />
            <ResponsiveContainer width="100%" height={height}>
                <AreaChart
                    className='chart'
                    data={data}
                    margin={{ left: -20, top: 10, right: 10 }}
                >
                    <XAxis
                        dataKey={"date"}
                        interval={interval}
                        tick={<CustomXAxistick />}
                    />

                    <YAxis
                        tickCount={studentList.length}
                        axisLine={false}
                        tick={<CustomYAxisTick />}
                    />
                    <CartesianGrid horizontal={true} vertical={false} stroke="#cccccc" />
                    <Line type="monotone" dataKey="students" stroke="rgb(69, 130, 180)" />
                    <Area type="monotone" dataKey="students" fill={adminView.viewMode ? "#00A2E3" : "#5CE5FA"} fillOpacity={0.3} />
                    <Tooltip />
                </AreaChart >
            </ResponsiveContainer>

        </div>

    );
}
AdminAttendanceFlowChart1.propTypes = {
    title: PropTypes.string.isRequired,
    studentList: PropTypes.array.isRequired,
    height: PropTypes.number.isRequired,
    handleTimeFrameChart: PropTypes.func.isRequired,
    timeFrameChart: PropTypes.shape({
        month: PropTypes.string.isRequired,
        year: PropTypes.number.isRequired
    }).isRequired,
    timeFramesAvailable: PropTypes.shape({
        months: PropTypes.array.isRequired,
        years: PropTypes.array.isRequired
    }).isRequired
};
export default AdminAttendanceFlowChart1;