import { MenuContext } from "../../../Admin/AdminImports";
import { useContext } from "react";
import "./SettingView.css"
function SettingView() {
    const { menu } = useContext(MenuContext);
    const ToDisplay = menu.find(({ status }) => status).component;
    return <div className="SettingView">
        <>
            <ToDisplay />
        </>
    </div>
}
export default SettingView;