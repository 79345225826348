import './Admin.css'
import { Outlet } from "react-router-dom";
import { useState, useContext } from "react";
import {
    SideBar,
    SystemPreferences, darkModePallete
} from "./AdminImports.js"
import {
    dashBoardIcon,
    studentsIcon,
    teachersIcon,
    eventsIcon,
    settingsIcon,
    logouticon
} from "../assetsImports.js"
import { useEffect } from 'react';
import { useMemo } from 'react';


function Admin() {
    const { adminView } = useContext(SystemPreferences);

    const [menu, setMenu] = useState([
        { name: 'Dashboard', link: '/Admin', icon: dashBoardIcon, status: true },
        { name: 'Students', link: 'Students', icon: studentsIcon, status: false },
        { name: 'Teachers', link: 'Teachers', icon: teachersIcon, status: false },
        { name: 'Events', link: 'Events', icon: eventsIcon, status: false },
        { name: 'Settings', link: 'Settings', icon: settingsIcon, status: false },
        { name: 'Logout', link: '/', icon: logouticon, status: false }
    ]);

    const handleMenu = (optionName) => {

        const newMenuValue = [...menu];
        newMenuValue.forEach((option) => {
            if (option['name'] === optionName) option['status'] = true;
        });
        setMenu([...newMenuValue]);
    }
    const selectFontSize = useMemo(() => adminView['fontSize'].find(({ status }) => status)?.name, [adminView])
    useEffect(() => {
        const manageSize = () => {
            const root = document.documentElement;
            switch (selectFontSize) {
                case 'Small':
                    root.style.setProperty('--font-size-bodyText', '15px')
                    root.style.setProperty('--font-size-title-cat2', '15px')
                    root.style.setProperty('--font-size-title-cat1', '16px');
                    break;
                case 'Medium':
                    root.style.setProperty('--font-size-bodyText', '16px');
                    root.style.setProperty('--font-size-title-cat2', '16px')
                    root.style.setProperty('--font-size-title-cat1', '18px');
                    break;
                case 'Large':
                    root.style.setProperty('--font-size-bodyText', '18px');
                    root.style.setProperty('--font-size-title-cat2', '18px')
                    root.style.setProperty('--font-size-title-cat1', '20px');
                    break;
                default:
                    break;
            }
        };
        if (selectFontSize)
            manageSize();

    }, [selectFontSize]);


    return (
        <div className="Admin"
            style={adminView.viewMode ? darkModePallete.primaryBackground : {}}>

            <SideBar handleMenu={handleMenu} menu={menu} />
            <Outlet />

        </div>
    );
}

export default Admin;