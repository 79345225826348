import './TopWidgets.css'
import { useContext } from "react";
import { SystemPreferences, darkModePallete } from "../../../Admin/AdminImports.js";


function TopWidgets({ widgets }) {
    const { adminView } = useContext(SystemPreferences);
    return (
        <div className='widget-container'>
            {
                widgets.map(
                    (item, i) =>
                        <div
                            style={adminView.viewMode ? darkModePallete.secondaryBackground : {}}
                            className='widget'
                            key={i.toString()}>
                            <div className='widget-icon'>
                                <img src={item['icon']} alt='widget icon' />
                            </div>
                            <div className='widget-text'>
                                <p style={adminView.viewMode ? {color:"#cccccc"} : {}}>
                                    {item['name']}
                                </p>
                                <h2 style={adminView.viewMode ? darkModePallete.textColor : {}}>
                                    {item['totalNumber']}
                                </h2>
                            </div>

                        </div>
                )
            }
        </div>
    )
}

export default TopWidgets