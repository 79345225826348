import { useContext } from 'react';
import { teacherContext, SystemPreferences } from '../../../Admin/AdminImports';
import './Pagination.css';

const Pagination = () => {
    const { totalPosts, postsPerPage, currentPage, setCurrentPage } = useContext(teacherContext);
    const { adminView } = useContext(SystemPreferences);

    const totalPages = Math.ceil(totalPosts / postsPerPage);
    let pages = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pages.push(i);
    }

    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(currentPage - halfMaxPagesToShow, 1);
    let endPage = Math.min(currentPage + halfMaxPagesToShow, totalPages);

    if (currentPage <= halfMaxPagesToShow) {
        endPage = Math.min(maxPagesToShow, totalPages);
    } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        startPage = Math.max(totalPages - maxPagesToShow + 1, 1);
    }

    const displayedPages = pages.slice(startPage - 1, endPage);

    return (
        <div className={`pagination`} data-testid={`pagination`}>
            <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
            >
                &laquo;
            </button>
            {
                displayedPages.map((page, id) => (
                    <button
                        key={id}
                        onClick={() => setCurrentPage(page)}
                        className={page === currentPage ? 'active' : ''}
                        style={adminView.viewMode && page === currentPage ? { backgroundColor: "#3fa9f5" } : {}}
                    >
                        {page}
                    </button>
                ))
            }
            <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
            >
                &raquo;
            </button>
        </div>
    )
}

export default Pagination