import './App.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';

import Admin from './Admin/Admin';
import {
  Dashboard,
  AdminStudentSec,
  AdminTeacherSec,
  Events,
  Settings,
  SystemPreferencesProvider
} from "./Admin/AdminImports.js"

import Login from './reusableComps/Login/Login.js';
import Home from './Home/Home.js';
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route index element={<Home />} />
      <Route path='login' element={<Login />} />
      <Route path='Admin' element={
        <SystemPreferencesProvider>
          <Admin />
        </SystemPreferencesProvider>
      }>
        <Route index element={<Dashboard />}></Route>
        <Route path='Students' element={<AdminStudentSec />}></Route>
        <Route path='Teachers' element={<AdminTeacherSec />}></Route>
        <Route path='Events' element={<Events />}></Route>
        <Route path='Settings' element={<Settings />}></Route>
      </Route>
    </Route>
  )
)

function App() {

  return (
    <RouterProvider router={router} />
  );
}

export default App;
