import { useState, useEffect } from "react";
import { Header, InfoCard, TeacherNavBar, TeachersList, Pagination, teacherContext, teachers } from "../../Admin/AdminImports.js";
import './AdminTeacherSec.css'

function Teachers() {
    //selected Teacher from grid
    const [selTeach, setSelTeach] = useState(teachers[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(9);
    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = teachers.slice(firstPostIndex, lastPostIndex);
    const totalPosts = teachers.length;

    const handleSetSelTeach = (index) => setSelTeach(() => {
        return teachers[index];
    });
    useEffect(() => {
        const handleResize = () => {
            window.innerWidth <= 1500 ?
                setPostsPerPage(9) :
                setPostsPerPage(12)
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize',handleResize);
        };
    }, []);

    return (
        <div data-testid="Teachers" className="Teachers">
            <Header menuName={"Teachers"} />
            <TeacherNavBar teachers={teachers} />
            <teacherContext.Provider value={{ selTeach, handleSetSelTeach, currentPosts, postsPerPage, totalPosts, currentPage, setCurrentPage }}>
                <div className="gridInfoCard">
                    <div className="grid">
                        <TeachersList />
                        <Pagination />
                    </div>
                    <InfoCard />
                </div>

            </teacherContext.Provider>
        </div>
    );
}

export default Teachers;