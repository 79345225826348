import { useState, useEffect, useContext } from 'react';
import { UpperSec, BasicDetails, AttendanceGradeGraph, SystemPreferences, darkModePallete, StudentContext } from '../../../Admin/AdminImports'
import './AdminStudentDetails.css';


//===========conversion & data extraction funcitons============//
//=============================================================//
const monthsOfAttendancesAvailable = (student, year) => {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    let months = [];

    student['presence'].forEach(
        ({ date }) => {
            const dateFormat = new Date(date);
            const month = monthNames[dateFormat.getMonth()];
            if (!months.includes(month) && year === dateFormat.getFullYear()) months.push(month);
        }
    );

    return months;
};
const yearsOfAttendancesAvailable = (student) => {
    let years = [];

    student['presence'].forEach(
        ({ date }) => {
            const dateFormat = new Date(date);
            const year = dateFormat.getFullYear();
            if (!years.includes(year)) years.push(year);
        }
    );

    return years;
};

//===================================================//
function AdminStudentDetails() {
    const { studentSelected } = useContext(StudentContext)
    const { adminView } = useContext(SystemPreferences);

    const [timeFramesAvailable, setTimeFrameAvailable] = useState(
        {
            months: [...monthsOfAttendancesAvailable(
                studentSelected, new Date().getFullYear()
            )],
            years: [...yearsOfAttendancesAvailable(
                studentSelected
            )]
        }
    );
    // Get the months of the current academic year based on the student list and the current year
    const currentAcademicYearMonths = monthsOfAttendancesAvailable(studentSelected, new Date().getFullYear());
    // Get the current month of attendance by selecting the last month from the current academic year months
    const currentMonthOfAttendance = currentAcademicYearMonths[currentAcademicYearMonths.length - 1];
    const [timeFrame, setTimeFrame] = useState(
        {
            month: currentMonthOfAttendance, year: new Date().getFullYear()
        }
    );
    const [graphOptions, setGraphOptions] = useState(
        [
            { name: 'Attendance', status: true },
            { name: 'Tests', status: false },
            { name: 'CourseWork', status: false },
            { name: 'Exams', status: false }
        ]
    )
    const handleGraphOptions = (newOption) => {
        const newOptionSelected = [...graphOptions];
        newOptionSelected.forEach((option) => {
            option['status'] = false;
            if (option['name'] === newOption)
                option['status'] = true;

        });
        setGraphOptions([...newOptionSelected]);
    }
    // Update the time frame chart when a new time frame is selected
    const handleTimeFrame = (newTimeFrame) => {
        if (newTimeFrame.year !== timeFrame.year) {
            const monthsInSelectedYear = monthsOfAttendancesAvailable(
                studentSelected,
                newTimeFrame.year
            );
            const firstMonthOfSelectedYear = monthsInSelectedYear[0];
            setTimeFrame({ month: firstMonthOfSelectedYear, year: newTimeFrame.year });
        } else setTimeFrame({ ...newTimeFrame });

    };
    // Update the available months when the time frame chart changes
    const newStudentId = studentSelected['stud_id'];
    useEffect(() => {
        setTimeFrameAvailable({
            years: [...yearsOfAttendancesAvailable(
                studentSelected
            )],
            months: [...monthsOfAttendancesAvailable(studentSelected, timeFrame['year'])]
        });
    }, [timeFrame, newStudentId, studentSelected]);

    //uptdating time frame on selection of new student.
    useEffect(() => {
        setTimeFrame(
            {
                month: currentMonthOfAttendance, year: new Date().getFullYear()
            }
        )
    }, [currentMonthOfAttendance]);


    return (
        <div
            className="AdminStudentDetails"
            style={
                adminView.viewMode ?
                    { ...darkModePallete.shadow, ...darkModePallete.secondaryBackground } :
                    {}
            }
        >
            <UpperSec />
            <div className='infoSection'>
                <BasicDetails />
                <AttendanceGradeGraph
                    timeFramesAvailable={timeFramesAvailable}
                    student={studentSelected}
                    timeFrame={timeFrame}
                    graphOptions={graphOptions}
                    handleGraphOptions={handleGraphOptions}
                    handleTimeFrame={handleTimeFrame}
                />
            </div>

        </div>
    );
}

export default AdminStudentDetails;