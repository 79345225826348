export const teachers = [
    {
        photo: "https://randomuser.me/api/portraits/men/1.jpg",
        firstName: "John",
        lastName: "Doe",
        email: "john.doe@example.com",
        phoneNumber: "5551234",
        dob: "1991/09/01",
        gender: "female",
        address: "99 av. du large, kibenga, bujumbura, burundi",
        discipline: [
            "Mathematics",
            "Physics"
        ],
        about: "John is an experienced teacher with a passion for STEM education. He enjoys helping students develop a strong foundation in scientific concepts.",
        classes: [
            { name: "Algebra I", section: "A" },
            { name: "Physics", section: "B" }
        ],
        students: 25,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: true },
            { date: '2023/09/03', presence: false }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/women/2.jpg",
        firstName: "Jane",
        lastName: "Smith",
        email: "jane.smith@example.com",
        phoneNumber: "5555678",
        dob: "1991/09/01",
        gender: "female",
        address: "99 av. du large, kibenga, bujumbura, burundi",
        discipline: [
            "English",
            "Literature"
        ],
        about: "Jane is an engaging and passionate English teacher. She aims to inspire students to develop a love for reading and writing.",
        classes: [
            { name: "English Literature", section: "A" },
            { name: "Creative Writing", section: "B" }
        ],
        students: 20,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: true },
            { date: '2023/09/03', presence: true }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/men/2.jpg",
        firstName: "Michael",
        lastName: "Johnson",
        email: "michael.johnson@example.com",
        dob: "1991/09/01",
        gender: "male",
        address: "99 av. du large, kibenga, bujumbura, burundi",
        phoneNumber: "5559012",
        discipline: [
            "History",
            "Geography"
        ],
        about: "Michael is a knowledgeable and engaging history and geography teacher. He strives to make his lessons interactive and thought-provoking.",
        classes: [
            { name: "World History", section: "A" },
            { name: "Human Geography", section: "B" }
        ],
        students: 22,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/women/1.jpg",
        firstName: "Emily",
        lastName: "Davis",
        email: "emily.davis@example.com",
        dob: "1991/09/01",
        gender: "male",
        address: "99 av. du large, kibenga, bujumbura, burundi",
        phoneNumber: "5553456",
        discipline: [
            "Biology",
            "Chemistry"
        ],
        about: "Emily is a dedicated science teacher with a passion for hands-on learning. She enjoys incorporating lab experiments and interactive activities into her lessons.",
        classes: [
            { name: "Biology", section: "A" },
            { name: "Chemistry", section: "B" }
        ],
        students: 24,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: true },
            { date: '2023/09/03', presence: true }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/men/3.jpg",
        firstName: "William",
        lastName: "Brown",
        email: "william.brown@example.com",
        phoneNumber: "5557890",
        dob: "1991/09/01",
        gender: "female",
        address: "99 av. du large, kibenga, bujumbura, burundi",
        discipline: [
            "Art",
            "Design"
        ],
        about: "William is a creative and inspiring art and design teacher. He encourages his students to explore their artistic talents and develop their unique style.",
        classes: [
            { name: "Introduction to Art", section: "A" },
            { name: "Graphic Design", section: "B" }
        ],
        students: 18,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/women/3.jpg",
        firstName: "Olivia",
        lastName: "Taylor",
        email: "olivia.taylor@example.com",
        phoneNumber: "5552345",
        dob: "1991/09/01",
        gender: "female",
        address: "99 av. du large, kibenga, bujumbura, burundi",
        discipline: [
            "Music",
            "Performing Arts"
        ],
        about: "Olivia is a talented music and performing arts teacher. She works to foster creativity and encourage her students to express themselves through the arts.",
        classes: [
            { name: "Band", section: "A" },
            { name: "Drama", section: "B" }
        ],
        students: 21,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: true },
            { date: '2023/09/03', presence: true }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/men/4.jpg",
        firstName: "Alexander",
        lastName: "Lee",
        email: "alexander.lee@example.com",
        phoneNumber: "5556789",
        dob: "1991/09/01",
        gender: "male",
        address: "99 av. du large, kibenga, bujumbura, burundi",
        discipline: [
            "Computer Science",
            "Information Technology"
        ],
        about: "Alexander is a knowledgeable and passionate computer science and IT teacher. He enjoys helping students develop their coding and problem-solving skills.",
        classes: [
            { name: "Introduction to Computer Science", section: "A" },
            { name: "Web Development", section: "B" }
        ],
        students: 23,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: true },
            { date: '2023/09/03', presence: false }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/women/4.jpg",
        firstName: "Isabella",
        lastName: "Garcia",
        email: "isabella.garcia@example.com",
        phoneNumber: "5550123",
        dob: "1991/09/01",
        gender: "female",
        address: "99 av. du large, kibenga, bujumbura, burundi",
        discipline: [
            "Spanish",
            "Foreign Languages"
        ],
        about: "Isabella is a skilled and engaging Spanish teacher. She aims to immerse her students in the language and culture, helping them develop strong communication skills.",
        classes: [
            { name: "Spanish I", section: "A" },
            { name: "Spanish II", section: "B" }
        ],
        students: 19,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: true },
            { date: '2023/09/03', presence: true }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/men/5.jpg",
        firstName: "Daniel",
        lastName: "Gonzalez",
        email: "daniel.gonzalez@example.com",
        phoneNumber: "5554567",
        dob: "1991/09/01",
        gender: "female",
        address: "99 av. du large, kibenga, bujumbura, burundi",
        discipline: [
            "Physical Education",
            "Health"
        ],
        about: "Daniel is an energetic and enthusiastic physical education and health teacher. He encourages his students to develop healthy habits and active lifestyles.",
        classes: [
            { name: "Physical Education", section: "A" },
            { name: "Health and Wellness", section: "B" }
        ],
        students: 22,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true }
        ]
    }, {
        photo: "https://randomuser.me/api/portraits/women/5.jpg",
        firstName: "Sophie",
        lastName: "Johnson",
        email: "sophie.johnson@example.com",
        phoneNumber: "5551234",
        dob: "1987/05/14",
        gender: "female",
        address: "45 Elm St, Springfield, IL, USA",
        discipline: [
            "Mathematics",
            "Physics"
        ],
        about: "Sophie is a dedicated math and physics teacher with a passion for instilling a love for numbers in her students.",
        classes: [
            { name: "Calculus", section: "C" },
            { name: "Physics", section: "A" }
        ],
        students: 28,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: true },
            { date: '2023/09/03', presence: true }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/men/6.jpg",
        firstName: "Liam",
        lastName: "Smith",
        email: "liam.smith@example.com",
        phoneNumber: "5559876",
        dob: "1993/11/22",
        gender: "male",
        address: "321 Maple Rd, Toronto, ON, Canada",
        discipline: [
            "Chemistry",
            "Biology"
        ],
        about: "Liam has a keen interest in the natural sciences and loves conducting experiments with his students.",
        classes: [
            { name: "Organic Chemistry", section: "B" },
            { name: "Microbiology", section: "C" }
        ],
        students: 25,
        presence: [
            { date: '2023/09/01', presence: false },
            { date: '2023/09/02', presence: true },
            { date: '2023/09/03', presence: true }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/women/6.jpg",
        firstName: "Ava",
        lastName: "Brown",
        email: "ava.brown@example.com",
        phoneNumber: "555543",
        dob: "1985/07/30",
        gender: "female",
        address: "88 Pine St, Auckland, New Zealand",
        discipline: [
            "English",
            "Literature"
        ],
        about: "Ava is a passionate literature teacher who loves to dive deep into classical and contemporary works with her students.",
        classes: [
            { name: "English Literature", section: "A" },
            { name: "Creative Writing", section: "B" }
        ],
        students: 30,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/men/7.jpg",
        firstName: "Noah",
        lastName: "Davis",
        email: "noah.davis@example.com",
        phoneNumber: "5554321",
        dob: "1990/03/18",
        gender: "male",
        address: "12 Oak Ave, London, UK",
        discipline: [
            "History",
            "Geography"
        ],
        about: "Noah is enthusiastic about teaching history and geography and believes in learning from the past to shape the future.",
        classes: [
            { name: "World History", section: "B" },
            { name: "Geopolitics", section: "C" }
        ],
        students: 20,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: true },
            { date: '2023/09/03', presence: false }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/women/7.jpg",
        firstName: "Olivia",
        lastName: "Martinez",
        email: "olivia.martinez@example.com",
        phoneNumber: "5558765",
        dob: "1994/10/09",
        gender: "female",
        address: "19 Coral Rd, Madrid, Spain",
        discipline: [
            "Art",
            "Design"
        ],
        about: "Olivia is an art and design teacher who inspires creativity and encourages students to express themselves through various mediums.",
        classes: [
            { name: "Painting", section: "A" },
            { name: "Graphic Design", section: "C" }
        ],
        students: 18,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/men/8.jpg",
        firstName: "James",
        lastName: "Lee",
        email: "james.lee@example.com",
        phoneNumber: "5553456",
        dob: "1989/01/05",
        gender: "male",
        address: "67 Silver St, Sydney, Australia",
        discipline: [
            "Computer Science",
            "Mathematics"
        ],
        about: "James is a tech-savvy computer science and mathematics teacher who loves to integrate technology into his teaching.",
        classes: [
            { name: "Algorithms", section: "A" },
            { name: "Linear Algebra", section: "B" }
        ],
        students: 26,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: true },
            { date: '2023/09/03', presence: false }
        ]
    },
    {
        photo: "https://randomuser.me/api/portraits/women/8.jpg",
        firstName: "Emma",
        lastName: "Garcia",
        email: "emma.garcia@example.com",
        phoneNumber: "5556789",
        dob: "1988/12/12",
        gender: "female",
        address: "34 Cedar Blvd, Mexico City, Mexico",
        discipline: [
            "Music",
            "Drama"
        ],
        about: "Emma is an enthusiastic music and drama teacher who encourages students to find their voice and perform confidently.",
        classes: [
            { name: "Vocal Training", section: "C" },
            { name: "Theater Arts", section: "B" }
        ],
        students: 24,
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: true },
            { date: '2023/09/03', presence: true }
        ]
    }
]