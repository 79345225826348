import "./FloatViewEvent.css";
import expandIcon from "../../../icons/expandIcon.svg";
import clockIcon from "../../../icons/clockIcon.svg";
import locationIcon from "../../../icons/locationIcon.svg";
import { EventContext } from "../context/calendarContext";
import { useRef, useEffect, useContext } from "react";

const FloatViewEvent = ({ viewEvent, dayBlockData, handleFloatingView }) => {
    const isPersonalEvent = dayBlockData.people.length === 1 && dayBlockData.people[0] === "personal@email.com";
    const blockGroup = isPersonalEvent ? "Personal" : "Shared";
    const floatingView = useRef(null);
    const { handleViewEvent } = useContext(EventContext);

    useEffect(() => {
        const floatingCard = floatingView.current;

        if (viewEvent)
            floatingCard.addEventListener('click', () => {
                handleViewEvent(dayBlockData);
                handleFloatingView();
            });

        if (floatingCard)
            return () => {
                floatingCard.removeEventListener('click', () => { });
            };

    }, [viewEvent, handleFloatingView, dayBlockData, handleViewEvent])



    return viewEvent &&
        <div id="FloatViewEvent"
            className='FloatViewEvent'
            data-testid={`FloatViewEvent-${dayBlockData.title}`}
            ref={floatingView}
        >
            <div className='upperPart'>
                <span><strong>{blockGroup}</strong> </span>
                <span>-</span>
                <div className='people' >{
                    dayBlockData.people.map((person, i) =>
                        <span data-testid={`dayBlockData.people`} key={person + i.toString()}>{person}</span>
                    )
                }</div>
                <img src={expandIcon} alt="expand Icon"></img>
            </div>
            <div className='middlePart'>
                <h2>{dayBlockData.title}</h2>
                <div className='elem'><img src={clockIcon} alt='clock icon'></img>
                    <span data-testid={`dayBlockData.date`}>{dayBlockData.date}</span>
                </div>
                <div className='elem1'><img src={locationIcon} alt='location icon'></img>
                    <span data-testid={`dayBlockData.location`}>{dayBlockData.location}</span>
                </div>
            </div>
        </div >;
}

export default FloatViewEvent;