import React from 'react'
import './Header.css'
import userIcon from '../../icons/userIcon.svg';
import { useContext } from "react";
import { SystemPreferences, darkModePallete } from "../../Admin/AdminImports.js";

function Header({ menuName }) {
    const { adminView } = useContext(SystemPreferences);
    const headerContents = {
        searchBarPlaceholder: 'search',
        userInfo: {
            greetingMessage: 'Hello, ',
            userFirstName: 'userFirstName',
            profileImage: userIcon,
        },

    }
    return (
        <nav className='nav-bar' data-testid={`Header`}style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.shadow } : {}}>

            <div className='header-items' style={adminView.viewMode ? darkModePallete.textColor : {}}>
                <h3>
                    {menuName}
                </h3>

                <div className='user-info-sec'>
                    <div className='user-info'>
                        <p>
                            {headerContents.userInfo.greetingMessage}
                        </p>

                        <h6>{headerContents.userInfo.userFirstName}</h6>

                        <img src={`${headerContents.userInfo.profileImage}`} alt='user profile'>
                        </img>
                    </div>
                </div>

            </div>

        </nav>
    )
}

export default Header