import { useState, useEffect, useContext } from 'react';
import { SystemPreferences, darkModePallete } from "../../../Admin/AdminImports.js"
import { addIcon, closeIcon } from "../../../assetsImports.js"
import './PopUpAdd.css';

const fieldExtractor = (object) => {
    const fields = [];
    for (let key in object) {
        fields.push(object[key]);
    }
    return fields
}
const StringInput = ({ name }) => {
    const { adminView } = useContext(SystemPreferences);
    return < label >
        <span>
            {name}
        </span>
        <input
            style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.textColor, borderColor: "transparent" } : {}}
            type='text'
        >
        </input>
    </label >
}
const BooleanInput = ({ name }) => {
    const [knob, setKnob] = useState(false)
    const handleKnob = () => setKnob((prevVal) => !prevVal)
    return (
        <label>
            <span>
                {name}
            </span>
            <div className='knob'>
                <div
                    className='btn'
                    style={knob ? { right: "1px" } : { left: "1px" }}
                    onClick={() => handleKnob()}
                ></div>
            </div>
        </label>
    )
};
const DateInput = ({ name }) => {
    const { adminView } = useContext(SystemPreferences);
    return <label>
        <span>
            {name}
        </span>
        <input
            style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.textColor, borderColor: "transparent" } : {}}
            type="date"
        >
        </input>
    </label>
};
const NumberInput = ({ name }) => {
    const { adminView } = useContext(SystemPreferences);
    return <label>
        <span>
            {name}
        </span>
        <input
            style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.textColor, borderColor: "transparent" } : {}}
            type="number"
        >
        </input>
    </label>
};
const ImageInput = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const { adminView } = useContext(SystemPreferences);
    const handleFileChange = (event) => {
        const file = event.target.files[0];

        setSelectedFile(event.target.files[0]);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else setPreviewImage(null);

    };
    const handleDelImage = () => {
        setPreviewImage(null);
        setSelectedFile(null);
    }
    const handleAddImage = () => {
        document.getElementById("imageInput").click();
    }
    return (
        <div>
            <span className='imgField'>
            </span>
            <div className='FileInput'>
                <img src={selectedFile ? previewImage : addIcon}
                    style={adminView.viewMode ? { filter: "brightness(1000%) contrast(100%) hue-rotate(0deg)" } : {}}
                    alt={selectedFile ? "Student Photo" : 'add icon'}></img>
                <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />
                <div
                    onClick={() => handleAddImage()}
                    className='addImg'
                    style={adminView.viewMode ? darkModePallete.btnBlue : {}}
                >Update Image</div>
                <div
                    onClick={() => handleDelImage()}
                    className='delImg'
                    style={adminView.viewMode ? darkModePallete.textColor : {}}
                >Remove Image</div>
            </div>
        </div>
    )
};
const SubFields = ({ name, value }) => {
    return (
        <div className='SubField'>
            {name}
            <div className='SubFields' >
                {
                    value.map((field) => {
                        switch (field.type) {
                            case "file":
                                return <ImageInput key={field.name} name={field.name} />
                            case "string":
                                return <StringInput key={field.name} name={field.name} />
                            case "number":
                                return <NumberInput key={field.name} name={field.name} />
                            case "boolean":
                                return <BooleanInput key={field.name} name={field.name} />
                            default:
                                return <></>;
                        }
                    })
                }
            </div>
        </div>
    )
}

function PopUpAdd({ dataTemplate, handlePopUpAdd }) {
    const fields = fieldExtractor(dataTemplate);
    const { adminView } = useContext(SystemPreferences)
    useEffect(() => {

        const popUp = document.getElementById("PopUpAdd");
        const handleClick = (event) => {
            if (popUp && !popUp.contains(event.target))
                handlePopUpAdd()
        }
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [handlePopUpAdd]);

    return <form className='PopUpAdd'
        data-testid={`popUpEdit-adminStudentAddExport`}
        id='PopUpAdd'
        style={adminView.viewMode ? { ...darkModePallete.tertiaryBackground, color: "white", boxShadow: `none` } : {}}
    >
        <img src={closeIcon} style={adminView.viewMode ? darkModePallete.icon : {}} 
        data-testid={`popUpEdit-close-icon-adminStudentAddExport`}
        className='closeIcon' alt="icon to close" onClick={() => { handlePopUpAdd() }}></img>
        <div className='fields'>

            {
                fields.map((field) => {
                    switch (field.type) {
                        case "file":
                            return <ImageInput key={field.name} name={field.name} />
                        case "string":
                            return <StringInput key={field.name} name={field.name} />
                        case "boolean":
                            return <BooleanInput key={field.name} name={field.name} />
                        case "date":
                            return <DateInput key={field.name} name={field.name} />
                        case "number":
                            return <NumberInput key={field.name} name={field.name} />
                        case "array":
                            return <SubFields key={field.name} name={field.name} value={field.value} />
                        default:
                            return <></>;
                    }
                })
            }
        </div>
        <div className='saveDiscard'>
            <button className='save' style={adminView.viewMode ? darkModePallete.btnBlue : {}} onClick={(e) => { e.preventDefault() }}>Save</button>
            <button className='discard' style={adminView.viewMode ? darkModePallete.textColor : {}} onClick={(e) => { e.preventDefault(); handlePopUpAdd() }}>Discard</button>
        </div>
    </form>
}
export default PopUpAdd