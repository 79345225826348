import { useState, useContext } from 'react';
import './InviteMember.css';
import { darkModePallete, SystemPreferences } from "../../../../Admin/AdminImports.js"

const InviteMember = ({ closeInvite }) => {
    const { adminView } = useContext(SystemPreferences);
    const [emails, setEmails] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === ' ' && inputValue.trim() !== '') {
            addEmail(inputValue.trim());
        }
    };

    const addEmail = (email) => {
        if (validateEmail(email) && !emails.includes(email)) {
            setEmails([...emails, email]);
        }
        setInputValue('');
    };

    const removeEmail = (emailToRemove) => {
        setEmails(emails.filter((email) => email !== emailToRemove));
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    return (
        <div className={`invite-container`}
            style={adminView.viewMode ? { ...darkModePallete.tertiaryBackground } : {}}
        >
            <div className={`invite-action`}>
                <div className={`invite-action-header`}>
                    <h4> Send and invite to new members </h4>
                    <p>Enter the email address of the user(s) you want to invite and choose the roles they should have.</p>

                </div>
                <div className={`invite-action-email`}>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className={`invite-action-items`}>
                            <label htmlFor='email'>Email address</label>
                            <div className="email-tags">
                                {emails.map((email, index) => (
                                    <div key={index} className="email-tag">
                                        {email}
                                        <span
                                            className="remove-email"
                                            data-testid="remove-email"
                                            onClick={() => removeEmail(email)}
                                        >
                                            &times;
                                        </span>
                                    </div>
                                ))}
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Enter email and press space"
                                    className="invite-action-input"
                                />
                            </div>
                        </div>

                        <div className={`invite-action-items`}>
                            <label htmlFor='role'>Select team member roles</label>
                            <select id='role' className="invite-field" defaultValue="">
                                <option value="" disabled></option>
                                {
                                    ['Admin', 'Teacher', 'Student'].map((role, id) =>
                                        <option key={id} value={role}>{role}</option>
                                    )}
                            </select>
                        </div>

                        <div className={`invite-button`}>
                            <button
                                className={`invite-action-cancel`}
                                onClick={() => closeInvite()}
                            >Cancel</button>

                            <button type='reset' className={`invite-action-submit`} > Save changes</button>

                        </div>
                    </form>
                </div>
            </div>

            <div className={`invite-description`}
                style={adminView.viewMode ? { ...darkModePallete.secondaryBackground } : {}}>
                <h4>Role Description</h4>
                {[{ roleTitle: 'Admin', roleDetail: 'Can view, edit and manage everything' }, { roleTitle: 'Teacher', roleDetail: 'Can view, edit and manage students details only' }, { roleTitle: 'Student', roleDetail: 'Can view only' }
                ].map((roleData, id) =>
                    <div className={`invite-description-details`} key={id}>
                        <h5>{roleData.roleTitle}</h5>
                        <p>{roleData.roleDetail}</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default InviteMember