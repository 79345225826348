import { useContext, useState } from 'react';
import {
    StudentContext, PopUpExport,
    SystemPreferences, darkModePallete, PopUpAdd
} from '../../../Admin/AdminImports';
import './AdminStudentAddExport.css';


function AdminStudentAddExport() {
    const { toExportVal, handleExportAll } = useContext(StudentContext);
    const { adminView } = useContext(SystemPreferences);
    const [popUpAdd, setPopUpAdd] = useState(false);
    const [popUpExport, setPopUpExport] = useState(false);
    const handlePopUpExport = () => setPopUpExport((prevVal) => !prevVal);
    const handlePopUpAdd = () => setPopUpAdd((prevVal) => !prevVal);
    const [newStudent, setNewStudent] = useState({
        photo: { name: "Profile", type: "file", value: "" },
        lastName: { name: "Last Name", type: "string", value: "" },
        firstName: { name: "First Name", type: "string", value: "" },
        dateOfBirth: { name: "Date Of Birth", type: "date", value: "" },
        religion: { name: "Religion", type: "string", value: "" },
        gender: { name: "Gender", type: "string", value: "" },
        class: { name: "Class", type: "string", value: "" },
        classCompletionStatus: { name: "Class Completion Status", type: "boolean", value: "" },
        address: { name: "Addres", type: "string", value: "" },
        parentOne: {
            name: "Parent One", type: "array", value: [
                { name: "Full Name", type: "string", value: "" },
                { name: "Phone Number", type: "number", value: "" },
                { name: "Email", type: "string", value: "" }
            ]
        },
        parentTwo: {
            name: "Parent Two", type: "array", value: [
                { name: "Full Name", type: "string", value: "" },
                { name: "Phone Number", type: "number", value: "" },
                { name: "Email", type: "string", value: "" }
            ]
        }
    });
    // eslint-disable-next-line
    const handleNewStudent = () => setNewStudent(() => { });
    
    const selectedNum = toExportVal.length;
    return (
        <div className="AdminStudentAddExport" style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.shadow } : {}}>
            <div
                className="Add-btn"
                onClick={() => handlePopUpAdd()}
                data-testid={`AdminStudentAddExport-PopUpEdiAddbtn`}
                style={adminView.viewMode ? { backgroundColor: "white", color: "#222222" } : {}}
            >
                Add
            </div>

            <div className="ExportAll-btn"
                onClick={() => {
                    if (selectedNum === 0) {
                        handleExportAll(true);
                        handlePopUpExport();
                    } else {
                        handlePopUpExport();
                    }
                }}
                data-testid={`AdminStudentAddExport-PopUpExport`}
                style={adminView.viewMode ? { backgroundColor: "#0159A1" } : {}}
            >
                Export {selectedNum === 0 ? 'All' : `(${selectedNum})`}
            </div>
            {popUpAdd && <PopUpAdd dataTemplate={newStudent} handlePopUpAdd={handlePopUpAdd} />}

            {popUpExport && <PopUpExport handlePopUpExport={handlePopUpExport} toExportVal={toExportVal} />}
        </div>
    );
}

export default AdminStudentAddExport;