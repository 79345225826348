import { useContext, useState } from "react";
import { ConnectAccount, ChangePassword, DeleteAccount, SystemPreferences, darkModePallete } from "../../../../Admin/AdminImports.js";

import './AccountManager.css';

const AccountManager = () => {
    const { adminView } = useContext(SystemPreferences);
    const [openConnectAccount, setOpenConnectAccount] = useState(false);
    const [openChangePwd, setOpenChangePwd] = useState(false);
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
    const handleOpenConnectAccount = () => {
        return setOpenConnectAccount(!openConnectAccount);
    }
    const handleCancelConnectAccount = () => {
        return setOpenConnectAccount(false);
    }

    const handleOpenChangePwd = () => {
        return setOpenChangePwd(!openChangePwd);
    }

    const handleCancelChangePwd = () => {
        setOpenChangePwd(false);
    }

    const handleOpenDeleteAccount = () => {
        return setOpenDeleteAccount(!openDeleteAccount);
    }

    const handleCancelDeleteAccount = () => {
        setOpenDeleteAccount(true);
    }

    return (
        <div className={`account-manager-container`}>
            <div className={`account-manager-subtitles`}>
                <h4 style={adminView.viewMode ? darkModePallete.textColor : {}}>Accounts</h4>
            </div>

            <div className={`accounts-container`} >
                <div className={`accounts-title`}>
                    <p style={adminView.viewMode ? { color: "gray" } : {}}>
                        Connect an email account of choice which you would like to use for login
                    </p>
                </div>
                <div className={`accounts-email-container`}>
                    {/* list of emails added */}
                    <div className={`account-email`}>
                        <p className={`email`}>designers@widerange.com</p>
                    </div>
                    <div className={`account-email`}>
                        <p className={`email`}>dev@widerange.com</p>
                    </div>
                    {/* function to add login in email or account in the list above */}
                        <div onClick={handleOpenConnectAccount} className={`accounts-email-function`}>
                            <p>+</p>
                            <p>Connect another account</p>
                        </div>
                        {
                            openConnectAccount && (
                                <ConnectAccount closeConnectAccount={handleCancelConnectAccount}/>
                        )}
                </div>
            </div>

            <div className={`account-manager-subtitles`} >
                <h4 style={adminView.viewMode ? darkModePallete.textColor : {}}>Password</h4>
            </div>

            <div className={`account-password`}>
                <button data-testid={`changePassbtn`}  onClick={handleOpenChangePwd} >Change password</button>
            </div>

            {
                openChangePwd && 
                (
                    <ChangePassword 
                        onSend={handleOpenChangePwd}
                        onCancelBack={handleCancelChangePwd}
                    />
                )
            }

            <div className={`account-manager-subtitles`}>
                <h4 style={adminView.viewMode ? darkModePallete.textColor : {}}>Remove Account</h4>
                <p style={adminView.viewMode ? { color: "gray" } : {}}>You can do "Disable account" to take a break</p>
            </div>

            <div className={`account-remove`}>
                <div className={`account-dis-btn`}>
                    <button>Disable account</button>
                </div>

                <div onClick={handleOpenDeleteAccount} className={`account-del-btn`}>
                    <button>Delete account</button>
                </div>
                    {
                        openDeleteAccount 
                        && (<DeleteAccount
                                onDelete={handleOpenDeleteAccount}
                                onCancel={handleCancelDeleteAccount}
                            />
                    )}
            </div>
        </div>
    )
}

export default AccountManager;