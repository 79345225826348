import {
    XAxis, YAxis, CartesianGrid, Line, Tooltip, AreaChart, Area, BarChart, Bar, ResponsiveContainer
} from 'recharts';
import { Fragment, useState, useContext } from 'react';
import { SystemPreferences, darkModePallete } from "../../../../Admin/AdminImports.js";

const colorPalette = [
    "#4582B4", "#F9733B", "#FAB43C", "#FA3C5D",
    "#19E501", "#FAAD27", "#FAE028", "#F92900",
    "#FA009D", "#5C5C5C"
];

const attendanceDataConvert = (student, month, year) => {

    const Presence = [...student['presence']];
    const Days = [
        "Monday", "Tuesday", "Wednesday",
        "Thursday", "Friday", "Saturday", "Sunday"
    ];
    const Months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const dayOfmonthSelected = [];
    const convertedFormat = [];

    Presence.forEach(({ date, presence }) => {
        const dateFormat = new Date(date);
        const day = Days[dateFormat.getDay()];
        const monthVal = Months[dateFormat.getMonth()];
        const yearVal = dateFormat.getFullYear();

        const isMonthFound = monthVal === month;
        const isYearFound = year === yearVal;

        if (!dayOfmonthSelected.includes(day)) {
            if (isMonthFound && isYearFound) {
                dayOfmonthSelected.push(day);
                if (presence) convertedFormat.push({ day: day, attendance: 1 });
                else convertedFormat.push({ day: day, attendance: 0 });
            }
        } else {
            const index = dayOfmonthSelected.indexOf(day);
            if (presence && monthVal === month && year === yearVal)
                convertedFormat[index].attendance = convertedFormat[index].attendance + 1;
        }
    });
    return convertedFormat;
}
const OptionsBar = ({ graphOptions, handleGraphOptions }) => {
    const isFirstOptionAndTrue = (option) =>
        option['status'] ?
            { borderTopLeftRadius: "10px", backgroundColor: 'white', color: '#006676' } :
            { borderTopLeftRadius: "10px" };
    const isNotFirstOptionButTrue = (option) =>
        option['status'] ? { backgroundColor: 'white', color: '#006676' } :
            {};
    return (
        <div className='options'>
            {
                graphOptions.map(
                    (option, i) =>
                        <div
                            key={i.toString()}
                            onClick={() => handleGraphOptions(option['name'])}
                            className='option'
                            style={
                                i === 0 ?
                                    isFirstOptionAndTrue(option) :
                                    isNotFirstOptionButTrue(option)
                            }
                        >{option['name']}</div>
                )
            }
        </div>
    );
}
const CourseSelector = ({ coursesSelected, handleCoursesSelected }) => {
    const { adminView } = useContext(SystemPreferences);
    return (
        <div className='courseSelector'>
            {
                coursesSelected.map(
                    (course, i) =>
                        <div
                            onClick={() => handleCoursesSelected(course['name'])}
                            className='course'
                            key={i.toString()}
                            style={course['clicked'] ?
                                adminView.viewMode ? { ...darkModePallete.textColor, border: `3px solid ${course['color']}` } : {} :
                                adminView.viewMode ? darkModePallete.textColor : {}}
                        ><div className='dot' style={{ border: `4px solid ${course['color']}` }}></div>{course['name']}</div>
                )
            }
        </div >
    )
}
const TimeFrameSelector = ({ timeFramesAvailable, handleTimeFrame, timeFrame }) => {
    const { adminView } = useContext(SystemPreferences);
    return (
        <div className='TimeFrame' style={adminView.viewMode ? darkModePallete.textColor : {}}>
            <div className='monthSec'>
                {timeFrame['month']}

                <div className='months'>
                    {
                        timeFramesAvailable['months'].map(
                            (month, i) =>
                                <span
                                    key={i.toString()}
                                    className='month'
                                    onClick={() => handleTimeFrame({ month: month, year: timeFrame['year'] })}
                                >{month}</span>
                        )
                    }
                </div>
            </div>
            <div className='yearSec'>
                {timeFrame['year']}
                <div className='years'>
                    {
                        timeFramesAvailable['years'].map(
                            (year, i) =>
                                <span
                                    key={i.toString()}
                                    className='year'
                                    onClick={() => handleTimeFrame({ month: timeFrame['month'], year: year })}
                                >{year}</span>
                        )
                    }
                </div>
            </div>
        </div>
    )
};

const testExamCourseworkExtraction = (courses) => {
    const courseList = courses.map(({ name }) => name);
    const testResults = [];
    const courseWorkResults = [];
    const examResults = [];

    courses.forEach(({ name, grades }) => {
        const otherCoursesObj = {};
        const otherCoursesList = courseList.filter((course) => course !== name);
        otherCoursesList.forEach((course) => otherCoursesObj[course] = '-');

        grades.forEach(({ maxGrade, exam, coursework, test, date }) => {
            let isDateTestAvailable = false;
            let isDateCourseWorkAvailable = false;
            let isDateExamAvailable = false;
            //Extracting all tested done.
            if (test) {
                testResults.forEach((testResult) => {
                    if (testResult['date'] === date) {
                        testResult[name] = test;
                        if (testResult['maxGrade'] < maxGrade) testResult['maxGrade'] = maxGrade;
                        isDateTestAvailable = true;
                    }
                });
                if (!isDateTestAvailable)
                    testResults.push({ [name]: test, maxGrade, date: date, ...otherCoursesObj });
            }
            //Extracting all coursework done.
            if (coursework) {
                courseWorkResults.forEach((courseworkResult) => {
                    if (courseworkResult['date'] === date) {
                        courseworkResult[name] = coursework;
                        if (courseworkResult['maxGrade'] < maxGrade) courseworkResult['maxGrade'] = maxGrade;
                        isDateCourseWorkAvailable = true;
                    }
                });
                if (!isDateCourseWorkAvailable)
                    courseWorkResults.push({ [name]: coursework, maxGrade, date: date, ...otherCoursesObj });
            }
            //Extracting all exams done.
            if (exam) {
                examResults.forEach((examResult) => {
                    if (examResult['date'] === date) {
                        examResult[name] = exam;
                        if (examResult['maxGrade'] < maxGrade) examResult['maxGrade'] = maxGrade;
                        isDateExamAvailable = true;
                    }
                });
                if (!isDateExamAvailable)
                    examResults.push({ [name]: exam, maxGrade, date: date, ...otherCoursesObj });
            }

        });
    });

    for (let i = 0; i < testResults.length; i++) {
        for (let j = 0; j < testResults.length; j++) {
            let buffer;
            const date1 = new Date(testResults[i]['date']);
            const date2 = new Date(testResults[j]['date'])
            if (date1 < date2) {
                buffer = testResults[i];
                testResults[i] = testResults[j];
                testResults[j] = buffer;
            }

        }

    }

    return { testResults, courseWorkResults, examResults, courseList };

}
const GradeGraph = ({   courses, coursesSelected, graphOptions }) => {
    const data = testExamCourseworkExtraction(courses);
    const { adminView } = useContext(SystemPreferences);
    let respectiveData = [];
    graphOptions.forEach(({ name, status }) => {
        if (status && name !== 'Attendance') {
            switch (name) {
                case 'Tests':
                    respectiveData = [...data.testResults];
                    break;
                case 'CourseWork':
                    respectiveData = [...data.courseWorkResults];
                    break;
                case 'Exams':
                    respectiveData = [...data.examResults];
                    break;
                default:
                    break;
            }
        }
    })

    const courseSelectedClicked = coursesSelected.filter(({ clicked }) => clicked);

    const CustomXAxistick = (props) => {
        const { x, y, payload } = props;
        const dateFormat = new Date(payload.value);
        const Days = [
            'Mon',
            'Tue',
            'Wed',
            'Thu',
            'Fri',
            'Sat',
            'Sun'];
        let displayValue = Days[dateFormat.getDay()];

        return (
            <text x={x} y={y} dx={0} dy={10}
                textAnchor="middle"
                style={{ fontSize: '12px' }}
                fill={adminView.viewMode ? "rgb(255, 255, 255)" : ""}>
                {`${displayValue}-${dateFormat.getDate()}`}
            </text>
        );
    };
    const CustomYAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <text x={x} y={y} dx={-10} dy={0}
                style={{ fontSize: '12px' }}
                fill={adminView.viewMode ? "rgb(255, 255, 255)" : ""}
                textAnchor="middle">
                {payload.value}
            </text>
        );
    };
    const CustomToolTip = (props) => {
        const { payload, active, label } = props;
        let payloadDataToDisplay = [];

        if (payload !== null) payloadDataToDisplay = payload.filter((pld) => pld.value !== '-');

        const colorFinder = (courseName) => {
            let colorToUse = '';
            courseSelectedClicked.forEach(({ name, color }) => {
                if (name === courseName) colorToUse = color;
            });
            return colorToUse;
        }
        if (active && payload && payload.length > 0 && payloadDataToDisplay.length > 0) {

            return (
                <div className="custom-tooltip">
                    <span className="label">{`${label}`}</span>
                    <div className='data'>
                        {
                            payloadDataToDisplay.map((pld, i) =>
                                <div key={i.toString()} >
                                    <div className='courseInfo' style={{ color: `${colorFinder(pld.dataKey)}` }}>
                                        <span className='course'>{pld.dataKey.slice(0, 4)}: </span>
                                        <span className='grade'>{pld.value}</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            );
        }
        else return null;
    };

    return (
        <ResponsiveContainer  width="100%" height="100%">
            <BarChart
                data={respectiveData}
                margin={{ left: -30, top: 10, right: 20 }}
            >
                <XAxis
                    dataKey={"date"}
                    tick={<CustomXAxistick />}
                />
                <YAxis
                    dataKey={"maxGrade"}
                    tick={<CustomYAxisTick />}
                />
                <CartesianGrid horizontal={true} vertical={false} stroke="#eee" />
                {
                    courseSelectedClicked.length > 0 ? courseSelectedClicked.map(({ name, color }, i) =>
                        <Bar
                            key={i.toString()}
                            type="monotone"
                            dataKey={name}
                            fill={color}
                            stroke={color}
                        />
                    ) : <></>
                }
                <Tooltip content={<CustomToolTip />} cursor={false} />
            </BarChart>
        </ResponsiveContainer>
    )
}
const AttendanceGraph = ({  student, timeFrame }) => {
    const data = attendanceDataConvert(student, timeFrame.month, timeFrame.year);
    const { adminView } = useContext(SystemPreferences);

    const maximumAttendace = (data) => {
        let count = 0;
        data.forEach(({ attendance }) => {
            if (attendance > count) count = attendance;

        });
        return count;
    };
    const CustomXAxistick = (props) => {
        const { x, y, payload } = props;
        const Days = [
            { full: 'Monday', short: 'Mon' },
            { full: 'Tuesday', short: 'Tue' },
            { full: 'Wednesday', short: 'Wed' },
            { full: 'Thursday', short: 'Thu' },
            { full: 'Friday', short: 'Fri' },
            { full: 'Saturday', short: 'Sat' },
            { full: 'Sunday', short: 'Sun' }];
        let displayValue = '';
        Days.forEach(({ full, short }) => {
            if (full === payload.value) {
                displayValue = short;
            }
        });
        return (
            <text x={x} y={y} dx={0} dy={15}
                textAnchor="middle" style={{ fontSize: '12px' }} fill={adminView.viewMode ? "rgb(255, 255, 255)" : ""}>
                {`${displayValue}`}
            </text>
        );
    };
    const CustomYAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <text x={x} y={y} dx={-10} dy={0}

                style={{ fontSize: '12px' }}
                textAnchor="middle"
                fill={adminView.viewMode ? "rgb(255, 255, 255)" : ""}>
                {payload.value}
            </text>
        );
    };
    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                data={data}
                className="chart"
                margin={{ left: -30, top: 10, right: 20 }}
            >
                <XAxis
                    dataKey={"day"}
                    tick={<CustomXAxistick />}
                    interval={0}
                />
                <YAxis
                    tickCount={maximumAttendace(data)}
                    dataKey={"attendance"}
                    tick={<CustomYAxisTick />}
                />
                <CartesianGrid horizontal={true} vertical={false} stroke="#eee" />
                <Line type="monotone" dataKey="attendance" stroke="rgb(69, 130, 180)" />
                <Area type="monotone" dataKey="attendance" fill={adminView.viewMode ? "#00A2E3" : "#5CE5FA"} fillOpacity={0.3} />
                <Tooltip />
            </AreaChart>
        </ResponsiveContainer>

    )
};
const GraphOptions = ({
    timeFrame, graphOptions,
    handleGraphOptions, timeFramesAvailable,
    handleTimeFrame, coursesSelected,
    handleCoursesSelected
}) => {

    const respectiveSelector = {
        "Attendance": TimeFrameSelector,
        "Tests": CourseSelector,
        "CourseWork": CourseSelector,
        "Exams": CourseSelector
    }
    let SelectorDisplay = '';
    const updatingSelectorDisplay = (respectiveSelector, graphOptions) => {
        graphOptions.forEach(({ name, status }) => {
            if (status) SelectorDisplay = respectiveSelector[name];
        });
    }
    updatingSelectorDisplay(respectiveSelector, graphOptions);
    return (
        <div className='GraphOptions'>
            <OptionsBar
                graphOptions={graphOptions}
                handleGraphOptions={handleGraphOptions}
            />
            <Fragment>
                <SelectorDisplay
                    timeFrame={timeFrame}
                    timeFramesAvailable={timeFramesAvailable}
                    handleTimeFrame={handleTimeFrame}
                    handleCoursesSelected={handleCoursesSelected}
                    coursesSelected={coursesSelected}
                />
            </Fragment>
        </div>
    )
};
const AttendanceGradeGraph = ({
    student, height,
    timeFrame, graphOptions, handleGraphOptions,
    timeFramesAvailable, handleTimeFrame
}) => {
    const { courses } = student;
    const respectiveGraph = {
        "Attendance": AttendanceGraph,
        "Tests": GradeGraph,
        "CourseWork": GradeGraph,
        "Exams": GradeGraph,
    }
    let GraphDisplay = '';
    const updatingGraphDisplay = (respectiveGraph, graphOptions) => {
        graphOptions.forEach(({ name, status }) => {
            if (status) GraphDisplay = respectiveGraph[name];
        });
    }
    updatingGraphDisplay(respectiveGraph, graphOptions);

    const courseSelectedIntializing = [];
    courses.forEach(({ name }, i) => courseSelectedIntializing.push({ name: name, clicked: false, color: colorPalette[i] }));
    courseSelectedIntializing[0]['clicked'] = true;
    const [coursesSelected, setCoursesSelected] = useState([...courseSelectedIntializing]);

    const handleCoursesSelected = (name) => {
        const newCoursesSelected = [...coursesSelected];
        newCoursesSelected.forEach((courseSelected) => {
            if (courseSelected['name'] === name) courseSelected['clicked'] = !courseSelected['clicked'];
        })
        setCoursesSelected([...newCoursesSelected]);
    };
   
    return (
        <div id="AttendanceGradeGraph"
            className='AttendanceGradeGraph'>
            <GraphOptions
                timeFrame={timeFrame}
                graphOptions={graphOptions}
                handleGraphOptions={handleGraphOptions}
                timeFramesAvailable={timeFramesAvailable}
                handleTimeFrame={handleTimeFrame}
                coursesSelected={coursesSelected}
                handleCoursesSelected={handleCoursesSelected}
            />
            <Fragment>
                <GraphDisplay
                    student={student}
                    timeFrame={timeFrame}
                    courses={courses}
                    coursesSelected={coursesSelected}
                    graphOptions={graphOptions}
                />
            </Fragment>

        </div>
    )
};

export default AttendanceGradeGraph;