import "./Settings.css"
import { useState, useContext } from "react";
import {
    SideBarSettings, Header,
    General, UserManagement,
    Billing, SysPreferences,
    SettingView, MenuContext,
    SystemPreferences, darkModePallete
} from "../../Admin/AdminImports.js"
import { billingIcon, preferencesIcon, userManagement, generalSettingIcon } from "../../assetsImports.js"


function Settings() {
    const { adminView } = useContext(SystemPreferences);
    const [menu, setMenu] = useState([
        { name: "General", status: true, component: General, icon: generalSettingIcon },
        { name: "User Management", status: false, component: UserManagement, icon: userManagement },
        { name: "System Preferences", status: false, component: SysPreferences, icon: preferencesIcon },
        { name: "Billing", status: false, component: Billing, icon: billingIcon }
    ]);
    const handleMenu = (name) => setMenu((prevMenu) => {
        const newMenu = [...prevMenu];
        newMenu.forEach((option) => {
            if (option.name === name) option.status = true;
            else option.status = false;
        });
        return newMenu;
    })
    return (
        <div data-testid="Settings" className="Settings">
            <Header menuName={"Settings"}  />
            <MenuContext.Provider value={{ menu, handleMenu }}>
                <div className="sideBarSettingView" style={adminView.viewMode ? { ...darkModePallete.secondaryBackground, ...darkModePallete.shadow } : {}}>
                    <SideBarSettings />
                    <SettingView />
                </div>
            </MenuContext.Provider>
        </div>
    );
}

export default Settings;