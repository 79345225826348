import "./SysPreferences.css"
import {
     InterfaceTheme,
    SideBarView, FontSize,
    SystemPreferences, darkModePallete
} from "../../../Admin/AdminImports.js"
import { useContext } from "react";
function SysPreferences() {
    const { adminView } = useContext(SystemPreferences);
    return <div
        className="SysPreferences"
        style={adminView.viewMode ? darkModePallete.textColor : {}}
    >
        <h1>SysPreferences</h1>
        <InterfaceTheme />
        <SideBarView />
        <FontSize />
        <div className="cancelSave">
            <button className="cancel">Cancel</button>
            <button className="saveChagnes">Save Changes</button>
        </div>
    </div>
}
export default SysPreferences;