import clockIcon from '../../../icons/clockIcon.svg';
import locationIcon from '../../../icons/locationIcon.svg';
import './ViewEvent.css'

function ViewEvent({ event, onClose }) {

    return (
        <div className={`ve-overlay`}>
            <div className={`ve-bg`}>
                <div className={`ve-container`}>

                    <div className={`ve-title ve-border`}>
                        <div className={`ve-color`} ></div>
                        <h2>{event.title}</h2>
                    </div>
                    <div className={`ve-time-date ve-border`}>
                        <div>
                            <img src={clockIcon} alt='clock' />
                        </div>
                        <div className={`ve-time`}>
                            <p>{event.time}</p>
                            <p>{event.duration}</p>
                            <p>
                                Occurs every weekday
                            </p>
                        </div>
                    </div>

                    <div className={`ve-location ve-border`}>
                        <img src={locationIcon} alt='location' />
                        <p>{event.location}</p>
                    </div>

                    <div className={`ve-details`}>
                        <div className={`ve-detail-title ve-border`}>
                            <h3> Meeting Details</h3>
                        </div>
                        <div className={`ve-detail`}>
                            {event.description}
                        </div>
                    </div>

                </div>
                <div className={`ve-invitees`}>
                <button className="ve-CloseBtn" onClick={onClose}>X</button>

                    <div className={`ve-invitees-title`}> <h3>Invitees</h3> </div>
                    <div className={`ve-people`} >
                        {
                            event.people.map((person, id) => (
                                <p key={id}> {person} </p>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewEvent