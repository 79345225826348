import React, { useState, useContext } from 'react';
import { CalendarContext, EventContext } from '../context/calendarContext';
import moment from 'moment';
import './CreateEvent.css';

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const SaveDiscard = ({ onSave, onClose }) => <div className="SaveDiscard">
    <button className="save" onClick={onSave} type="submit">Save</button>
    <button className="discard" type="button" onClick={onClose}>Discard</button>
</div>

const TextEditor = ({ text, setText }) => {
    const editorModules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            [ 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }
    const editorFormats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
         'image', 'video'
    ];
    // const [text, setText] = useState('');
    const handleText = (newText) => setText(newText);

    return <div
        className="TextEditor"
    >
        < ReactQuill
            theme={"snow"}
            modules={editorModules}
            formats={editorFormats}
            bounds=".TextEditor"
            value={text}
            onChange={handleText} />
    </div >
}

function validateEmail(email) {
    // const regex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // return regex.test(String(email).toLowerCase());
    return email;
}

const CreateEvent = ({ show, onClose }) => {
    const { month, year } = useContext(CalendarContext);
    const { handleAddEvent } = useContext(EventContext);
    const [title, setTitle] = useState('');
    const [emails, setEmails] = useState('');
    const [eventDate, setEventDate] = useState(`${year}-${String(month).padStart(2, '0')}-01`);
    const [startTime, setStartTime] = useState(moment().format('HH:mm'));
    const [duration, setDuration] = useState(60);
    const [reminder, setReminder] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [location, setlocation] = useState('');
    const [text, setText] = useState('');


    if (!show) {
        return null;
    }

    const handleFileChange = (event) => {
        setAttachment(event.target.files[0]);
    };

    const handleEmailChange = (event) => {
        setEmails(event.target.value);
    };

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        // const endDateTime = moment(eventDate + ' ' + startTime).add(duration, 'minutes').format('YYYY-MM-DD HH:mm');
        // const endTime = moment(eventDate + ' ' + startTime).add(duration, 'minutes').format('HH');
        const emailArray = emails.split(/[\s,;]+/).map(email => email.trim()).filter(email => email.length > 0);
        const validEmails = emailArray.filter(email => validateEmail(email));
        const invalidEmails = emailArray.length !== validEmails.length;
        const customDate = moment(eventDate).format('MMMM DD');
        const customDuration = duration > 59 ? Math.floor(duration / 60) + ' hr' : (duration === 0 || duration < 1 ? duration + ' sec' : duration + ' min');

        if (invalidEmails) {
            alert("One or more email addresses are invalid!");
            return;
        }

        const eventData = {
            title,
            emails: validEmails,
            // date: eventDate,
            date: customDate,
            startTime,
            endTime: customDuration,
            reminder,
            location,
            attachment: attachment ? attachment.name : '',
            description: text,
        };
        handleAddEvent(eventData);
        onClose();
    };

    return (
        <div className="CreateEvent-Overlay">
            <div className={`CreateEvent-bg`}>

                <div className="CreateEvent-Box">
                    <button onClick={onClose} className="CreateEvent-CloseBtn">X</button>
                    <h2>New Event</h2>
                    <input
                        className={`CreateEvent-FileInputLabel`}
                        type="file"
                        onChange={handleFileChange} />

                    <form className="CreateEvent-Form">
                    
                        <input type="text" placeholder="New Event"
                            data-testid={`event-title-input`}
                            value={title}
                            onChange={e => setTitle(e.target.value)} />

                        <input type="text" placeholder="Invite People"
                            data-testid={`event-emails-input`}
                            value={emails}
                            onChange={handleEmailChange} />

                        <input
                            type="date"
                            value={eventDate}
                            data-testid={`event-date-input`}
                            onChange={e => setEventDate(e.target.value)} />
                        <input
                            type="time"
                            value={startTime}
                            data-testid={`event-start-time-input`}
                            onChange={e => setStartTime(e.target.value)} />

                        <input type="number"
                            min="0"
                            placeholder="Duration (minutes)"
                            value={duration}
                            onChange={e => setDuration(e.target.value)} />
                        <select
                            value={reminder}
                            onChange={e => setReminder(e.target.value)}
                            data-testid={`event-reminder`}
                            >
                            <option value="">Select Reminder</option>
                            <option value="15min">15 Minutes Before</option>
                            <option value="30min">30 Minutes Before</option>
                            <option value="1hour">1 Hour Before</option>
                        </select>
                        <input
                            type="text"
                            placeholder=" Location "
                            data-testid={`event-location-input`}
                            value={location}
                            onChange={e => setlocation(e.target.value)} />


                    </form>

                    <div className="Text-Events">
                        <div className="EventDescriptAndTextEdit">
                            <TextEditor text={text} setText={setText} />
                            <SaveDiscard onSave={() => handleSubmit()} onClose={onClose} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CreateEvent;