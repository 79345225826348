import { useState, useContext } from "react";
import {
    BillingHistory, payementMethods,
    billingHistory, plan,
    PayementMethod, Cancelation, RecurrentPayment,
    SystemPreferences, darkModePallete
} from "../../../Admin/AdminImports.js"
import "./Billing.css"

function Billing() {
    const { adminView } = useContext(SystemPreferences);
    const [lspaymentMethods, setlsPaymentMethods] = useState([...payementMethods])
    const handleRemovePaymentMethods = (typeToRemove) => setlsPaymentMethods((prevVal) => {
        const newLsPaymentMethods = [...prevVal]
        return newLsPaymentMethods.filter(({ type }) => type !== typeToRemove)
    });
    return <div className="Billing" style={adminView.viewMode ? darkModePallete.textColor : {}}>
        <div className="title">
            <h2>Billing</h2>
            <span>Udpate Your Billing process</span>
        </div>
        <div className="options">
            <div className="historyCancel">
                <BillingHistory billingHistory={billingHistory} />
                <Cancelation plan={plan} />
            </div>
            <div className="payementMethRecurence">
                <PayementMethod lspaymentMethods={lspaymentMethods} handleRemovePaymentMethods={handleRemovePaymentMethods} />
                <RecurrentPayment />
            </div>
        </div>
    </div>
}
export default Billing;