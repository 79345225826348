import { useState } from 'react';
import './ChangePassword.css';

const ChangePassword = ({ onSend, onCancelBack }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSend = (e) => {
        e.preventDefault();
        // Validate and handle the input value
        if (validateEmail(inputValue)) {
            onSend(); // Trigger the onSend prop if email is valid
        } else {
            alert('Please enter a valid email address.');
        }
    };

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    return (
        <div className="change-password-overlay">
            <div className="change-password-box">
                <div className={`change-password-header`}>
                    <h4>Reset your password</h4>
                    <p>Enter the email address you used to register.</p>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>

                    <div className={`email-input-wrapper`}>
                        <input
                            data-testid={'email-input'}
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder="Enter email"
                            className="email-input"
                        />
                    </div>

                    <div className="change-password-buttons">
                        <button onClick={(e) => handleSend(e)} className="send-button" data-testid="send-instruction-btn">Send Instructions</button>
                        <button onClick={onCancelBack} className="back-button">Back</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;