import React, { useState } from 'react';
import { Toaster, toast } from 'sonner';
import './Login.css'

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // to replace later with database
    const dummyData = [
        { username: 'user1', password: 'password1' },
        { username: 'user2', password: 'password2' },
    ]

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    // authentication logic
    const handleSubmit = (event) => {
        event.preventDefault();
        setError('');

        const user = dummyData.find(
            (user) => user.username === username && user.password === password
        );

        if (user) {
            toast.success('Authentication successful');
            // then redirect/navigate to Dashboard route...

        }
        else {
            setError('Invalid username or password');
            toast.error('Invalid username or password. Please try again.');
        }
    };

    return (
        <div className='form-login-container'>
            <Toaster richColors />
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>

                <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={handleUsernameChange}
                    placeholder='Username'
                />


                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder='Password'
                />
                <p data-testid='error-msg'>{error}</p>

                <button className='button-login' type="submit" data-testid='login-button'>Login</button>

                <p>Forgot password? <span className='signup-link' /> Sign Up </p>

            </form>
        </div>
    );
};

export default Login