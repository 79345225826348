import { useContext, useEffect, useState } from 'react';
import { StudentContext, Filter, SystemPreferences, darkModePallete } from '../../../Admin/AdminImports';
import { filterIcon, closeIcon } from '../../../assetsImports';
import './StudentList.css';
import { useCallback } from 'react';

function StudentList() {
    const { data, handleStudentSelected, handleToExportVal, exportAll, handleExportAll } = useContext(StudentContext);
    const { adminView } = useContext(SystemPreferences);

    const [filterContainer, setFilterContainer] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [search, setSearch] = useState('');
    const [checkedItems, setCheckedItems] = useState([]);


    const applyFilter = (filterValues) => {
        setSelectedFilter(filterValues);
        setFilterContainer(false);
    };

    const filteredData = data.filter((student) => {
        // check filtered button
        const filterMatch = selectedFilter.length === 0 || selectedFilter.includes(student.class);

        const searchTerm = search.toLowerCase();
        const searchMatch = !searchTerm || student.firstName.toLowerCase().includes(searchTerm) ||
            student.lastName.toLowerCase().includes(searchTerm) ||
            student.class.toLowerCase().includes(searchTerm) ||
            student.stu_id.toLowerCase().includes(searchTerm) ||
            (`${student.firstName} ${student.lastName}`).toLowerCase().includes(searchTerm) ||
            (`${student.lastName} ${student.firstName}`).toLowerCase().includes(searchTerm);

        return filterMatch && searchMatch;

    });
    function checkboxHandler(e, student) {
        let isSelected = e.target.checked;

        if (isSelected) {
            setCheckedItems([...checkedItems, student]);
        }
        else {
            setCheckedItems(checkedItems.filter((data) => data.stu_id !== student.stu_id));
        }
    }
    const checkAllHandler = useCallback(() => {
        if (filteredData.length !== checkedItems.length) {
            handleExportAll(true);
            setCheckedItems(filteredData);
        } else {
            handleExportAll(false);
            setCheckedItems([]);
        }
    }, [filteredData, checkedItems, handleExportAll]);

    //------------------------------------
    //Extracting the values for export btn
    useEffect(() => {
        handleToExportVal(checkedItems);
    }, [checkedItems, handleToExportVal])
    //------------------------------------
    //Export all functionality
    useEffect(() => {
        if (exportAll && checkedItems.length === 0) { checkAllHandler(); }
    }, [exportAll, checkAllHandler, checkedItems])
    return (
        <div className='student-list-container' style={adminView.viewMode ? darkModePallete.secondaryBackground : {}}>

            <div className='student-list-search'>
                <div onClick={(e) => e.stopPropagation()}>
                    {filterContainer && <Filter filterData={data} onFilterSelect={applyFilter} closeFilter={() => setFilterContainer(false)} />}
                    <div className='filter-container' onClick={() => setFilterContainer(!filterContainer)} data-testid="filter-button">
                        <img src={filterIcon} alt='filter' style={adminView.viewMode ? darkModePallete.icon : {}} />
                        <p style={adminView.viewMode ? darkModePallete.textColor : {}}>Filter</p>
                    </div>
                </div>

                <div className='search-bar-wrapper'>
                    <input
                        placeholder='Search for students or ID'
                        type='text'
                        onChange={(e) => setSearch(e.target.value)}
                        id='search-input'
                        data-testid='search-input'
                        className={`search-bar`}
                    />
                </div>
            </div>
            {(selectedFilter) && <div className='selected-filter'>
                {selectedFilter.map((filter, index) =>
                    <button key={index}>
                        {filter}
                        <img
                            src={closeIcon}
                            alt='close icon'
                            onClick={() => setSelectedFilter(selectedFilter.filter(f => f !== filter))}
                            className="close-filter"
                        >
                        </img>
                    </button>
                )}
            </div>
            }

            <div className='student-table' style={adminView.viewMode ? { border: "none" } : {}}>

                <table>
                    <thead>
                        <tr>
                            {/* Checkbox prop: boolean */}
                            <th>
                                <input
                                    data-testid={`checkbox-selectAll`}
                                    type='checkbox'
                                    id='all-student-checkbox'
                                    onChange={checkAllHandler}
                                    checked={checkedItems.length === filteredData.length}
                                />
                            </th>

                            {/* columnDefs prop: string */}
                            <th>
                                <p>Photo</p>
                            </th>
                            <th>
                                <p>Name</p>
                            </th>
                            <th>
                                <p>Student ID</p>
                            </th>
                            <th>
                                <p>Class</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((student, keyID) =>
                            <tr
                                data-testid={`studentList-${student['stu_id']}`}
                                key={keyID}
                                onClick={() => handleStudentSelected(student.firstName, student.lastName)}
                                className={`${checkedItems.includes(keyID) ? 'checked-color' : ''}`}
                                style={adminView.viewMode ? darkModePallete.textColor : {}}
                            >
                                <td>
                                    <input
                                        className='individualCheckBox'
                                        type='checkbox'
                                        id={`studentList-checkBox-${student['stu_id']}`}
                                        data-testid={`studentList-checkBox-${student['stu_id']}`}
                                        checked={checkedItems.some((item) => item.stu_id === student.stu_id)}
                                        onChange={(e) => checkboxHandler(e, student)}
                                        value={keyID}
                                    />
                                </td>

                                <td>
                                    <img src={student.photo} alt='student avatar' />
                                </td>
                                <td>
                                    <p>
                                        {student.firstName + " " + student.lastName}
                                    </p>
                                </td>
                                <td>
                                    {student.stu_id}
                                </td>
                                <td>
                                    {student.class}
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default StudentList
