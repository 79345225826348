import './ConnectAccount.css';
import { useContext } from "react";
import { SystemPreferences, darkModePallete } from "../../../../../Admin/AdminImports.js"

const ConnectAccount = ({ closeConnectAccount }) => {
    const { adminView } = useContext(SystemPreferences);
    return (
        <div className="connect-container" style={adminView.viewMode ? { ...darkModePallete.tertiaryBackground } : {}}>
            <div className="invite-description">
                <div className="connect-action">
                    <div className="connect-action-header" >
                        <p style={adminView.viewMode ? { ...darkModePallete.legendTextColor } : {}}>Login Seamlessly</p>
                        <h4 style={adminView.viewMode ? { ...darkModePallete.textColor } : {}}>Connect your Account</h4>
                    </div>
                    <div className="connect-action-body">
                        {/* Add description, inputs, and other elements based on your design */}
                        <p className="connect-text" style={adminView.viewMode ? { ...darkModePallete.legendTextColor } : {}}>
                            By connecting your account, you can easily sync your settings and data.
                        </p>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="connect-input"
                        />
                        <button className="connect-button">Connect Now</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectAccount