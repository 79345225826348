import { goToIcon } from '../../../../assetsImports';
import { UsersList } from "../../../../Admin/AdminImports.js";
import './TeamManager.css';

const UserPermissionBtn = () => {
    return (
        <div className={`team-manager-permissions`}>
            <div className={`user-permissions-title`}>
                <p>
                    Manage user permissions
                </p>
            </div>
            <div className={`user-permissions-btn`}>
                <button>
                    <p>Go to user permissions</p>
                    <img src={goToIcon} alt="link" />
                </button>
            </div>
        </div>
    )
}


const TeamManager = () => {
    return (
        <div className={`team-manager-container`}>
            <UsersList />
            <UserPermissionBtn />
        </div>
    )
}

export default TeamManager;