import { useState } from "react";
import "./RecurrentPayement.css"
function RecurrentPayment() {
    const [recurrentPayment, setRecurrentPayment] = useState(false);
    const handleRectPaym = () => setRecurrentPayment((prevVal) => !prevVal)
    return <div className="RecurrentPayement">
        <div className="Title">
            <h4>
                Recurring Payement
            </h4>
            <span>Set your recurrent payement status.</span>
        </div>


        <div style={recurrentPayment ? { backgroundColor: "green" } : { backgroundColor: "#fd2020" }}
            className="knob">
            <div
                style={recurrentPayment ? { left: "40px" } : { left: "2px" }}
                onClick={() => handleRectPaym()}
                className="circle"></div>
            <span
                className="knobState"
                style={recurrentPayment ?
                    { left: "6px" } :
                    { left: "40px" }}
            >{recurrentPayment ? "ON" : "OFF"}</span>
        </div>
    </div>
}

export default RecurrentPayment;