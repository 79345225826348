import { dateDisplay } from "../../../../Admin/AdminImports.js"
import "./BillingHistory.css"
function BillingHistory({ billingHistory }) {

    return <div className="BillingHistory">
        <div className="HeaderElm">
            <div className="elm">Invoice Date</div>
            <div className="elm">Payment Date</div>
            <div className="Receipt">Receipt</div>
        </div>
        <div className="historyList">
            {
                billingHistory.map((elm, i) =>
                    <div key={i.toString()} className="receipt" data-testid={`BillingHistory-receipt`}>
                        <span className="elm" >{dateDisplay(elm.invoiceDate)}</span>
                        <span className="elm" >{dateDisplay(elm.paymentDate)}</span>
                        <button className="downloadElm">Download</button>
                    </div>
                )
            }
        </div>
    </div>

}

export default BillingHistory;